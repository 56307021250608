import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { Typography, Grid } from '@mui/material';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import CustomDropDown from '../../CommonComponents/Dropdown/CustomDropDown';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomTextField from '../../CommonComponents/TextField/CustomTextField';
import { getCountryListService } from '../../Services/getCountryListService';
import { Search } from 'react-feather';
import './MaintainContact.css';
import Loader from '../../CommonComponents/Loader/Loader';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import CustomTable from '../../CommonComponents/Table/CustomTable';
import CustomPagination from '../../CommonComponents/Table/CustomPagination';
import {
	searchContact,
	hqDeleteContact,
	exportContactCSV,
	exportContactExcel,
} from '../../Services/hqService';
import ContactContext from './ContactContext';
import ConfirmDailog from '../../CommonComponents/Dailog/ConfirmDailog';
import SuccessToast from '../../CommonComponents/Toast/SuccessToast';

const MaintainContact = () => {
	const { t } = useTranslation();
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedCountryName, setSelectedCountryName] = useState('All');
	const [allCountries, setAllCountries] = useState([]);
	const [fileFormat, setFileFormat] = useState('XLS');
	const [searchCriteria, setSearchCriteria] = useState('User ID');
	const [resultLoading, setResultLoading] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [openError, setOpenError] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [contacts, setContacts] = useState([]);
	const [showDailog, setShowDailog] = useState(false);
	const [openToast, setOpenToast] = useState(false);
	const [pageNum, setPageNum] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [reload, setReload] = useState(false);

	const user_info = useSelector((state) => state?.auth?.user);
	const colheaders = [
		{
			key: 'userId',
			name: t('maintain_mpc_userID'),
		},
		{
			key: 'name',
			name: t('maintain_mpc_mpcName'),
		},
		{
			key: 'email',
			name: t('maintain_mpc_email'),
		},
		{
			key: 'countryname',
			name: t('maintain_mpc_market'),
		},
		{
			key: 'divisionName',
			name: t('maintain_mpc_Division'),
		},
	];
	useEffect(() => {
		setResultLoading(true);
		getCountryListService()
			.then((countries) => {
				setAllCountries(countries?.result);

				return searchContact();
			})
			.then((response) => {
				setTableData(response?.result);
				setResultLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setResultLoading(false);
				setApiError(t('apiError'));
				setOpenError(true);
				setAllCountries([]);
			});
	}, [reload]);
	const handleDelete = async () => {
		try {
			let selectedContacts = contacts.join(',');
			console.log(selectedContacts);
			setShowDailog(false);
			setResultLoading(true);
			const result = await hqDeleteContact(selectedContacts);
			setResultLoading(false);
			if (result) {
				setContacts([]);
				setOpenToast(true);
				setReload(!reload);
			} else {
				throw new Error();
			}
		} catch (err) {
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	const handleSelectFileFormat = (value) => {
		setFileFormat(value);
	};
	const handleSearchCriteria = (value) => {
		setSearchCriteria(value);
	};
	const handleSearch = async () => {
		try {
			setResultLoading(true);
			const response = await searchContact(
				searchCriteria,
				searchTerm,
				0,
				pageSize
			);
			setResultLoading(false);
			setPageNum(0);
			setTableData(response?.result);
		} catch (err) {
			console.error(err);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};
	const handlePageChange = async (pageNo) => {
		try {
			setPageNum(pageNo - 1);
			setResultLoading(true);
			const response = await searchContact(
				searchCriteria,
				searchTerm,
				pageNo - 1,
				pageSize
			);
			setResultLoading(false);
			setTableData(response?.result);
		} catch (err) {
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const handleStartExport = async () => {
		try {
			if (fileFormat === 'XLS') {
				setResultLoading(true);
				const result = await exportContactExcel(selectedCountry);
				setResultLoading(false);
				if (result.status === 200) {
					saveAs(
						result.data,
						`PARTSEXL_MPCDATA_Export${Math.random()}${user_info.userName}_CONFIDENTIAL.xlsx`
					);
				} else {
					let error = new Error();
					throw error;
				}
			} else {
				setResultLoading(true);
				const result = await exportContactCSV(selectedCountry);
				setResultLoading(false);
				if (result.status === 200) {
					saveAs(
						result.data,
						`PARTSEXL_MPCDATA_Export${Math.random()}${user_info.userName}_CONFIDENTIAL.csv`
					);
				} else {
					let error = new Error();
					throw error;
				}
			}
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	return (
		<ContactContext.Provider value={{ contacts, setContacts }}>
			<div className="maintainContactWrapper">
				<Typography
					sx={{
						color: '#151C2D',
						fontSize: '14px',
						fontStyle: 'normal',
						fontWeight: 600,
						fontFamily: 'Arial, Helvetica, sans-serif',
					}}
				>
					{t('navlevel1_10')}
				</Typography>
				<hr />
				<br />
				<Grid container direction={'column'} rowGap={2}>
					<Grid item>
						<Grid container direction={'row'} gap={2}>
							{/* Country selector */}
							<Grid item>
								<Grid container direction="row" columnGap={2}>
									<Grid
										item
										sx={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										<Typography>{t('export_label_filter_country')}</Typography>
									</Grid>
									<Grid
										item
										sx={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										<AutoCompleteCountryDropdown
											width="18em"
											height="2em"
											placeholder="Identifying country"
											value={selectedCountryName}
											onChange={(newValue) => {
												if (newValue) {
													const country = allCountries.find(
														(country) => country.countryName === newValue
													);
													setSelectedCountryName(newValue);
													setSelectedCountry(country.countryisocode);
												} else {
													setSelectedCountry(null);
													setSelectedCountryName('All');
												}
											}}
											country="European"
										/>
									</Grid>
								</Grid>
							</Grid>
							{/* File selector */}
							<Grid
								item
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<Grid container direction="row" columnGap={2}>
									<Grid
										item
										sx={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										<Typography>{t('export_label_desired_format')}</Typography>
									</Grid>
									<Grid
										item
										sx={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										<CustomDropDown
											options={[
												{ id: 1, name: 'XLS' },
												{ id: 2, name: 'CSV' },
											]}
											multiple={false}
											label="Select file format"
											id="role-dropdown"
											onChange={handleSelectFileFormat}
											width="7em"
											value={fileFormat}
											defaultValue={'XLS'}
										/>
									</Grid>
									<Grid
										item
										sx={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										<CustomButton
											text={t('export_label_startexport_button')}
											width="auto"
											handleClick={handleStartExport}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container direction={'row'} gap={2}>
							{/* add and delete button */}
							<Grid
								item
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<Link to={'/contact/add'}>
									<CustomButton
										text={t('maintain_mpc_add')}
										height={'2rem'}
										width="auto"
									/>
								</Link>
							</Grid>
							<Grid
								item
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<CustomButton
									width={'auto'}
									height={'2rem'}
									text={t('maintain_mpc_delete')}
									handleClick={() => {
										setShowDailog(true);
									}}
									disabled={contacts.length === 0}
								/>
							</Grid>
							<Grid
								item
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<Typography>{'Search Criteria'}</Typography>
							</Grid>
							<Grid
								item
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<CustomDropDown
									options={[
										{ id: 1, name: 'User ID' },
										{ id: 2, name: 'Name' },
										{ id: 3, name: 'Email' },
										{ id: 4, name: 'Market' },
										{ id: 5, name: 'Division' },
									]}
									multiple={false}
									label="Select Search Criteria"
									id="search-dropdown"
									onChange={handleSearchCriteria}
									width="7em"
									value={searchCriteria}
									defaultValue={'User Id'}
								/>
							</Grid>
							{/* search bar */}
							<Grid
								item
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<CustomTextField
									width="18rem"
									placeholder={t("navlevel1_21")}
									height="2rem"
									handleKeyDown={handleKeyDown}
									handleChange={(e) => {
										setSearchTerm(e.target.value);
									}}
								/>
							</Grid>
							<Grid
								item
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<CustomButton
									width={'3rem'}
									height={'2rem'}
									startIcon={<Search />}
									handleClick={handleSearch}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<CustomTable
							page="contact/edit"
							tableData={tableData?.mpc}
							errorMessage={errorMessage}
							colheaders={colheaders}
							width={colheaders.length * 14}
							action={t('reseller_edit_datascroller_buttonedit')}
						/>
					</Grid>
					<Grid item>
						<CustomPagination
							totalRecords={tableData?.totalNumberOfRecord}
							handlePageChange={handlePageChange}
							pageSize={pageSize}
							pageNo={pageNum}
							totalPages={tableData?.numberOfPages}
						/>
					</Grid>
				</Grid>
				<br />
				<br />
				<ErrorToast
					open={openError}
					handleClose={handleCloseError}
					message={apiError}
				/>
				<Loader open={resultLoading} text={'Loading'} />
				<ConfirmDailog
					open={showDailog}
					closeDialog={() => {
						setShowDailog(false);
					}}
					heading={t('maintain_mpc_delete')}
					content={t('maintain_mpc_delete_message')}
					confirm={handleDelete}
				/>
				<SuccessToast
					open={openToast}
					message={t('requestSuccess')}
					handleClose={(event, reason) => {
						if (reason === 'clickaway') {
							return;
						}
						setOpenToast(false);
					}}
				/>
			</div>
		</ContactContext.Provider>
	);
};

export default MaintainContact;
