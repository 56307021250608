import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItem, Select, Box, FormControl, Grid } from '@mui/material';
import {
	setLanguageDropdown,
	setLanguages,
	setcurrentLanguage,
} from '../../Store/languageSlice';
import i18n from '../../utils/i18n';
import './languageSelector.css';

const LanguageSelector = () => {
	function importAllImages(img) {
		let images = {};
		img.keys().map((item, index) => {
			images[item.replace('./', '')] = img(item);
		});
		return images;
	}
	const images = importAllImages(
		require.context('../../Resources/media', false, /\.(gif|png|svg)$/)
	);
	const languageList = {
		languages: [
			{
				label: 'Bulgarian',
				code: 'BG',
				active: true,
				img: images['bulgaria.gif'],
			},
			{
				label: 'Czech',
				code: 'CZ',
				active: true,
				img: images['czechrepublic.gif'],
			},
			{
				label: 'Danish',
				code: 'DK',
				active: true,
				img: images['denmark.gif'],
			},
			{
				label: 'Dutch',
				code: 'NL',
				active: true,
				img: images['netherlands.gif'],
			},
			{
				label: 'English',
				code: 'GB',
				active: true,
				img: images['english.gif'],
			},
			{
				label: 'Estonian',
				code: 'EE',
				active: true,
				img: images['estonia.gif'],
			},
			{
				label: 'Finnish',
				code: 'FI',
				active: true,
				img: images['finland.gif'],
			},
			{
				label: 'French',
				code: 'FR',
				active: true,
				img: images['french.gif'],
			},
			{
				label: 'German',
				code: 'DE',
				active: true,
				img: images['german.gif'],
			},
			{
				label: 'Greek',
				code: 'GR',
				active: true,
				img: images['greece.gif'],
			},
			{
				label: 'Hungarian',
				code: 'HU',
				active: true,
				img: images['hungary.gif'],
			},
			{
				label: 'Italian',
				code: 'IT',
				active: true,
				img: images['italy.gif'],
			},
			{
				label: 'Latvian',
				code: 'LV',
				active: true,
				img: images['latvia.gif'],
			},
			{
				label: 'Lithuanian',
				code: 'LI',
				active: true,
				img: images['lithuania.gif'],
			},
			{
				label: 'Norwegian',
				code: 'NO',
				active: true,
				img: images['norway.gif'],
			},
			{
				label: 'Polish',
				code: 'PL',
				active: true,
				img: images['poland.gif'],
			},
			{
				label: 'Portuguese',
				code: 'PT',
				active: true,
				img: images['portugal.gif'],
			},
			{
				label: 'Romanian',
				code: 'RO',
				active: true,
				img: images['romania.gif'],
			},

			{
				label: 'Slovak',
				code: 'SK',
				active: true,
				img: images['slovakia.gif'],
			},
			{
				label: 'Slovenian',
				code: 'SI',
				active: true,
				img: images['slovenia.gif'],
			},
			{
				label: 'Spanish',
				code: 'ES',
				active: true,
				img: images['spain.gif'],
			},
			{
				label: 'Swedish',
				code: 'SE',
				active: true,
				img: images['sweden.gif'],
			},
		],
	};

	const dispatch = useDispatch();

	const languages = useSelector((state) => state?.languages.languages);

	const currentLanguage = useSelector(
		(state) => state?.languages.currentLanguage
	);
	const user_info = useSelector((state) => state?.auth?.user);

	const [selectedLanguage, setSelectedLanguage] = useState({});
	const [flag, setFlag] = useState(null);

	const handleChange = (value) => {
		localStorage.setItem('language', value);
		i18n.changeLanguage(value);
		setSelectedLanguage(value);
		dispatch(setcurrentLanguage(value));
		dispatch(setLanguageDropdown(true));
	};

	useEffect(() => {
		if (languages) {
			if (
				localStorage.getItem('language') &&
				localStorage.getItem('language') !== 'undefined'
			) {
				const index = languages?.findIndex(
					(lang) => lang.label === localStorage.getItem('language')
				);
				i18n.changeLanguage(languages[index]?.label);
				setSelectedLanguage(languages[index]);
				setFlag(languages[index]?.img);
			} else {
				if (user_info) {
					const country = user_info?.userContry;
					const userLanguage = languageList.languages.find(
						(lang) => lang.code === country
					);
					const english = languageList.languages.find(
						(lang) => lang.code === 'GB'
					);
					const defaultLanguage = userLanguage ? userLanguage : english;
					i18n.changeLanguage(defaultLanguage?.label);
					setSelectedLanguage(defaultLanguage);
					setFlag(defaultLanguage?.img);
					localStorage.setItem('language', defaultLanguage?.label);
					const currentLanguage = defaultLanguage?.label;
					dispatch(setcurrentLanguage(currentLanguage));
					setSelectedLanguage(languages);
					dispatch(setLanguages(languages));
				}
			}
		} else {
			setSelectedLanguage(languages);
			dispatch(setLanguages(languageList.languages));
		}
	}, [languages, user_info, dispatch]);
	return (
		<div className="languageSelector" data-testid="country-dropdown">
			<FormControl variant="standard">
				<Select
					MenuProps={{
						PaperProps: {
							sx: {
								backgroundColor: '#fff !important',
								display: 'flex',
								width: '350px',
								padding: '1em',
								flexWrap: 'wrap',
								alignItems: 'center',
								justifyContent: 'center',
								outline: 'unset',
								border: 'unset',
								'&::-webkit-scrollbar': { display: 'none' },

								'& .MuiMenuItem-root.Mui-selected': {
									background: '#ADC8D6',
								},
								'& .MuiMenuItem-root:hover': {
									backgroundColor: '#ADC8D6',
								},
							},
						},
					}}
					className="langBox"
					displayEmpty
					inputProps={{
						IconComponent: () => null,
						'data-testid': 'my-select',
					}}
					value={selectedLanguage}
					defaultValue=""
					renderValue={() => (
						<Box
							sx={{
								display: 'flex',
								gap: 1,
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<img src={flag} />{' '}
							<p>{currentLanguage === 'GB' ? 'English' : currentLanguage}</p>
						</Box>
					)}
				>
					<Grid container direction={'row'} columnGap={1} rowGap={2}>
						{languageList.languages?.map((option) => {
							return (
								<Grid
									item
									width={'23%'}
									onClick={() => {
										handleChange(option.label);
										setFlag(option.img);
									}}
								>
									<MenuItem
										style={{
											width: '4.35rem',
											fontFamily: 'Roboto',
											fontSize: '0.75rem',
											padding: '0.3rem 0.3rem 0',
											textTransform: 'uppercase',
											display: 'flex',
											flexDirection: 'column',
											padding: '1em',
											height: '6em',
											lineHeight: '20px',
											border: '1px solid #ccc',
										}}
										className="langOption"
										key={option.label}
										value={option.label}
									>
										<img src={option.img} />
										<p className="languageText">{option.label}</p>
									</MenuItem>
								</Grid>
							);
						})}
					</Grid>
				</Select>
			</FormControl>
		</div>
	);
};

export default LanguageSelector;
