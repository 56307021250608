import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Typography,
	Grid,
	FormControlLabel,
	Checkbox,
	Card,
} from '@mui/material';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import Loader from '../../CommonComponents/Loader/Loader';
import CustomTextField from '../../CommonComponents/TextField/CustomTextField';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import './MaintainContact.css';
import { getCountryListService } from '../../Services/getCountryListService';
import { roles } from '../../Constants/Permission';
import SuccessToast from '../../CommonComponents/Toast/SuccessToast';
import { getContactById, editContact } from '../../Services/mpcServices';
import { hqEditContact } from '../../Services/hqService';

const EditContact = () => {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();
	const currentRole = useSelector((state) => state?.role?.currentRole);
	const user_info = useSelector((state) => state?.auth?.user);

	const [resultLoading, setResultLoading] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [openError, setOpenError] = useState(false);
	const [openToast, setOpenToast] = useState(false);
	const [divison, setDivision] = useState([]);
	const [salesActivitycountryList, setSalesActivitycountryList] = useState([]);
	const [selectedSalesActivity, setSelectedSalesActivity] = useState([]);
	const [initialValues, setInitialValues] = useState(null);
	const [fieldErrors, setFieldErrors] = useState({});

	useEffect(() => {
		if (currentRole && user_info) {
			if (currentRole === roles.MPC) {
				setSalesActivitycountryList(user_info.countries);
			} else if (currentRole === roles.HQ) {
				getCountryListService()
					.then((countries) => {
						setSalesActivitycountryList(countries?.result);
					})
					.catch((err) => {
						console.error(err);
						setApiError(t('apiError'));
						setOpenError(true);
					});
			}
		}
	}, [currentRole, user_info]);
	useEffect(() => {
		setResultLoading(true);
		getContactById(id)
			.then((data) => {
				setResultLoading(false);
				setInitialValues(data?.result);
				if (
					data?.result?.division?.includes('B') &&
					data?.result?.division?.includes('T')
				) {
					setDivision([...divison, 'B', 'T']);
				} else if (data?.result?.division?.includes('T')) {
					setDivision([...divison, 'T']);
				} else if (data?.result?.division?.includes('B')) {
					setDivision([...divison, 'B']);
				}
				setSelectedSalesActivity(data?.result?.markets);
			})
			.catch((err) => {
				console.error(err);
				setApiError(t('apiError'));
				setOpenError(true);
				setResultLoading(false);
			});
	}, []);
	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	const submitForm = async (values) => {
		try {
			let requiredError = false;
			if (divison.length === 0) {
				requiredError = true;
				setFieldErrors((prevErr) => ({
					...prevErr,
					division: t('data_review_select_division_alert'),
				}));
			}
			const divisionArray = divison.sort((a, b) => b.localeCompare(a));
			values.division = divisionArray.length ? divison.join(',') : '';
			values.markets = selectedSalesActivity;
			if (!requiredError) {
				setResultLoading(true);
				const result =
					currentRole === roles.MPC
						? await editContact(values)
						: await hqEditContact(values);
				if (result) {
					if (result) {
						setResultLoading(false);
						setOpenToast(true);
					}
				} else {
					throw new Error();
				}
			}
		} catch (err) {
			console.error(err);
			setApiError(t('apiError'));
			setOpenError(true);
			setResultLoading(false);
		}
	};
	const validationSchema = Yup.object({
		userId: Yup.string().required(t("Mandatory")),
		name: Yup.string().required(t("Mandatory")),
		email: Yup.string().email().required(t("Mandatory")),
	});
	if (initialValues) {
		return (
			<Formik
				onSubmit={submitForm}
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
			>
				{(formik) => {
					const { handleChange, handleSubmit, setFieldError, errors } = formik;
					return (
						<form onSubmit={handleSubmit}>
							<div className="maintainContactWrapper">
								<Typography
									sx={{
										color: '#151C2D',
										fontSize: '14px',
										fontStyle: 'normal',
										fontWeight: 600,
										fontFamily: 'Arial, Helvetica, sans-serif',
									}}
								>
									{t('navlevel1_10')}
								</Typography>
								<hr />
								<br />
								<div>
									<Card
										sx={{
											padding: '2em 1.5em',
										}}
									>
										<Grid container width={'100%'} flexDirection={'column'}>
											<Typography
												sx={{
													fontWeight: 600,
													paddingBottom: '10px',
												}}
											>
												{t('add_mpc_title')}
											</Typography>
											<Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('maintain_mpc_userID')}
													</Typography>
													<CustomTextField
														placeholder={t('maintain_mpc_userID')}
														width="90%"
														height="2.5em"
														name="userId"
														disabled={true}
														// error={!!errors.name}
														handleChange={handleChange}
														defaultValue={initialValues?.userId}
													/>
													<ErrorMessage
														name="userId"
														component="div"
														className="error"
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('maintain_mpc_mpcName')}
													</Typography>
													<CustomTextField
														placeholder={t('maintain_mpc_mpcName')}
														width="90%"
														height="2.5em"
														name="name"
														// error={!!errors.name}
														handleChange={handleChange}
														defaultValue={initialValues?.name}
													/>
													<ErrorMessage
														name="name"
														component="div"
														className="error"
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('maintain_mpc_email')}
													</Typography>
													<CustomTextField
														placeholder={t('maintain_mpc_email')}
														width="90%"
														height="2.5em"
														name="email"
														// error={!!errors.name}
														handleChange={handleChange}
														defaultValue={initialValues?.email}
													/>
													<ErrorMessage
														name="email"
														component="div"
														className="error"
													/>
												</div>
											</Grid>
											<br />
											<Typography
												sx={{
													fontWeight: 600,
													paddingBottom: '10px',
												}}
											>
												{t('maintain_mpc_division')}
											</Typography>
											<Grid item>
												<Typography className="required">
													{t('reseller_label_divisionalfocus')}
												</Typography>
												<FormControlLabel
													width="25%"
													control={<Checkbox name="bus" />}
													label={t('maintain_mpc_division_bus')}
													checked={divison.includes('B')}
													onChange={(e) => {
														if (e.target.checked) {
															setFieldErrors((prevError) => {
																const updatedErrors = { ...prevError };
																delete updatedErrors['division'];
																return updatedErrors;
															});
															setDivision([...divison, 'B']);
														} else {
															const filteredDivision = divison.filter(
																(div) => div !== 'B'
															);
															if (filteredDivision.length === 0) {
																setFieldErrors((prevErr) => ({
																	...prevErr,
																	division: t(
																		'data_review_select_division_alert'
																	),
																}));
															}
															setDivision(filteredDivision);
														}
													}}
												/>
												<FormControlLabel
													width="25%"
													control={<Checkbox name="truck" />}
													label={t('maintain_mpc_division_truck')}
													checked={divison.includes('T')}
													onChange={(e) => {
														if (e.target.checked) {
															setFieldErrors((prevError) => {
																const updatedErrors = { ...prevError };
																delete updatedErrors['division'];
																return updatedErrors;
															});
															setDivision([...divison, 'T']);
														} else {
															const filteredDivision = divison.filter(
																(div) => div !== 'T'
															);
															if (filteredDivision.length === 0) {
																setFieldErrors((prevErr) => ({
																	...prevErr,
																	division: t(
																		'data_review_select_division_alert'
																	),
																}));
															}
															setDivision(filteredDivision);
														}
													}}
												/>
											</Grid>
											{'division' in fieldErrors && (
												<div style={{ color: 'red' }} className="error">
													{fieldErrors['division']}
												</div>
											)}
											<br />
											<Typography
												sx={{
													fontWeight: 600,
													paddingBottom: '10px',
												}}
											>
												{t('maintain_mpc_market')}
											</Typography>
											<Grid item>
												<Grid container direction={'row'}>
													{salesActivitycountryList?.map((country) => {
														return (
															<Grid
																item
																className="countryCheckBoxWrapper"
																width="25%"
															>
																<FormControlLabel
																	control={
																		<Checkbox name={country.countryisocode} />
																	}
																	label={
																		<Typography style={{ fontSize: '14px' }}>
																			{country.countryName}
																		</Typography>
																	}
																	checked={selectedSalesActivity.some(
																		(c) =>
																			c.countryisocode ===
																			country.countryisocode
																	)}
																	onChange={(e) => {
																		if (e.target.checked) {
																			setSelectedSalesActivity([
																				...selectedSalesActivity,
																				country,
																			]);
																		} else {
																			const filteredSalesActivity =
																				selectedSalesActivity.filter(
																					(data) =>
																						data?.countryisocode !==
																						country.countryisocode
																				);
																			setSelectedSalesActivity(
																				filteredSalesActivity
																			);
																		}
																	}}
																/>
															</Grid>
														);
													})}
												</Grid>
											</Grid>
											<br />
											<Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
												<CustomButton
													text={t('verify_reseller_button_cancel')}
													width="auto"
													handleClick={() => {
														navigate(-1);
													}}
												/>
												<CustomButton
													text={t('verify_reseller_button_save')}
													handleClick={handleSubmit}
													width="auto"
													marginLeft="2em"
												/>
											</Grid>
											<br />
										</Grid>
									</Card>
								</div>
							</div>
							<ErrorToast
								open={openError}
								handleClose={handleCloseError}
								message={apiError}
							/>
							<SuccessToast
								open={openToast}
								message={t('requestSuccess')}
								handleClose={(event, reason) => {
									if (reason === 'clickaway') {
										return;
									}
									setOpenToast(false);
									navigate(-1);
								}}
							/>
							<Loader open={resultLoading} text={'Loading'} />
						</form>
					);
				}}
			</Formik>
		);
	}
};

export default EditContact;
