import axios from 'axios';
import { baseURL, hq } from '../Constants/APIUrls';

export const getKPIReport = async (fromTime, toTime, Market) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(`${baseURL}${hq.kpiReport}`, {
			params: {
				fromTime,
				toTime,
				Market,
			},
		});
		return response ? response.data : [];
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const exportKPI = async (fromTime, toTime, Market) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(`${baseURL}${hq.exportKPI}`, {
			params: {
				Market,
				fromTime,
				toTime,
			},
			responseType: 'blob',
		});
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const searchContact = async (
	searchCriteria,
	searchTerm,
	pageNo,
	pageSize
) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const params = {
			pageNo,
			pageSize,
		};
		if (searchCriteria === 'User ID') {
			params.userId = searchTerm;
		} else if (searchCriteria === 'Name') {
			params.Name = searchTerm;
		} else if (searchCriteria === 'Email') {
			params.Email = searchTerm;
		} else if (searchCriteria === 'Market') {
			params.Market = searchTerm;
		} else if (searchCriteria === 'Division') {
			params.Division = searchTerm;
		}
		const response = await axiosInstance.get(`${baseURL}${hq.searchContact}`, {
			params,
		});
		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const hqAddMPC = async (values) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.post(
			`${baseURL}${hq.addContact}`,
			values
		);

		return response.status;
	} catch (err) {
		console.error(err);
		if (err.response.status === 409) {
			return err.response.status;
		} else throw new Error(err);
	}
};
export const hqDeleteContact = async (Id) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.delete(
			`${baseURL}${hq.deleteContact}`,
			{
				params: {
					Id,
				},
			}
		);
		return response.status === 200;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const hqGetContactById = async (Id) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(`${baseURL}${hq.getContactById}`, {
			params: {
				Id,
			},
		});
		return response.data;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const hqEditContact = async (values) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.post(
			`${baseURL}${hq.editContact}`,
			values
		);

		return response.status;
	} catch (err) {
		console.error(err);
		if (err.response.status === 409) {
			return err.response.status;
		} else throw new Error(err);
	}
};

export const exportContactExcel = async (Market) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(`${baseURL}${hq.getMpcExcel}`, {
			params: {
				Market,
			},
			responseType: 'blob',
		});
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const exportContactCSV = async (Market) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(`${baseURL}${hq.getMpcCsv}`, {
			params: {
				Market,
			},
			responseType: 'blob',
		});
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const dataReviewHQ = async (Markets, Division) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.post(
			`${baseURL}${hq.dataReview}`,
			null,
			{
				params: {
					Markets,
					Division,
				},
			}
		);
		return response.status === 200;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const getDataReviewExcelHQ = async (Markets) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${hq.getDataReviewExcel}`,
			{
				params: {
					Markets,
				},
				responseType: 'blob',
			}
		);
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
