import './EditReseller.css';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	IconButton,
	InputAdornment,
	TextField,
	Typography,
	Grid,
} from '@mui/material';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Loader from '../../CommonComponents/Loader/Loader';
import CustomTable from '../../CommonComponents/Table/CustomTable';
import CustomPagination from '../../CommonComponents/Table/CustomPagination';
import {
	getAllResellerService,
	deleteResellerById,
} from '../../Services/resellerServices';
import { roles } from '../../Constants/Permission';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import SuccessToast from '../../CommonComponents/Toast/SuccessToast';
import ConfirmDailog from '../../CommonComponents/Dailog/ConfirmDailog';

const EditReseller = () => {
	const [tableData, setTableData] = useState([]);
	const { t } = useTranslation();
	const [errorMessage, setErrorMessage] = useState('');
	const [pageNum, setPageNum] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [resultLoading, setLoading] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [openError, setOpenError] = useState(false);
	const [openToast, setOpenToast] = useState(false);
	const [reload, setReload] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [showDailog, setShowDailog] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState(null);

	const colheaders = [
		{
			key: 'name',
			name: t('companyName'),
		},
		{
			key: 'alternativename',
			name: t('reseller_label_alternativename'),
		},
		{
			key: 'address',
			name: t('reseller_label_address'),
		},
		{
			key: 'city',
			name: t('reseller_label_city'),
		},
		{
			key: 'identifiyingcountryName',
			name: t('reseller_label_country'),
		},
	];
	const user_info = useSelector((state) => state?.auth?.user);
	const currentRole = useSelector((state) => state?.role?.currentRole);

	const handleSearch = () => {
		setErrorMessage('');
		let countries = null;
		if (currentRole === roles.MPC) {
			const countryCode = user_info?.countries.map(
				(country) => country?.countryisocode
			);
			countries = countryCode.length ? countryCode.toString() : null;
		}
		setLoading(true);
		getAllResellerService(countries, pageNum, pageSize, searchTerm)
			.then((data) => {
				if (data.result.length === 0) {
					setLoading(false);
					setErrorMessage(`${t('SYSTEM_ERROR_Search3')} (${searchTerm})`);
				} else {
					setTableData(data.result);
					setLoading(false);
				}
			})
			.catch(() => {
				setApiError(t('apiError'));
				setLoading(false);
				setOpenError(true);
			});
	};
	const deleteReseller = async () => {
		try {
			setShowDailog(false);
			setLoading(true);
			const result = await deleteResellerById(selectedRecord);
			setLoading(false);
			if (result) {
				setOpenToast(true);
				setReload(!reload);
				setSelectedRecord(null);
			} else {
				throw new Error();
			}
		} catch (err) {
			console.error(err);
			setLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
			setSelectedRecord(null);
		}
	};
	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	useEffect(() => {
		let countries = null;
		if (currentRole === roles.MPC) {
			const countryCode = user_info?.countries.map(
				(country) => country?.countryisocode
			);
			countries = countryCode.length ? countryCode.toString() : null;
		}
		setLoading(true);
		getAllResellerService(countries, pageNum, pageSize, searchTerm)
			.then((data) => {
				setLoading(false);
				setTableData(data?.result);
			})
			.catch(() => {
				setLoading(false);
				setApiError(t('apiError'));
				setOpenError(true);
			});
	}, [currentRole, reload]);
	const handlePageChange = async (pageNo) => {
		try {
			setPageNum(pageNo - 1);
			let countries = null;
			if (currentRole === roles.MPC) {
				const countryCode = user_info?.countries.map(
					(country) => country?.countryisocode
				);
				countries = countryCode.length ? countryCode.toString() : null;
			}
			setLoading(true);
			const data = await getAllResellerService(
				countries,
				pageNo - 1,
				pageSize,
				searchTerm
			);
			setLoading(false);
			setTableData(data?.result);
		} catch (err) {
			console.error(err);
			setLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};
	return (
		<div className="EditResellerWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				{t('navlevel1_6')}
			</Typography>
			<hr />
			<Grid container direction={'column'}>
				<div className="searchInputWrapper">
					<div className="searchInput">
						<TextField
							variant="outlined"
							onKeyDown={handleKeyDown}
							placeholder={t('navlevel1_1')}
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
							sx={{
								'& .MuiOutlinedInput-input': {
									height: '100%',
									padding: '10px',
								},
								'& input::placeholder': {
									fontSize: '14px',
								},
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={handleSearch}
											edge="end"
											sx={{ width: '34px' }}
										>
											<Search />
										</IconButton>
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</div>
					<div>
						<p className="error-text">{errorMessage}</p>
					</div>
				</div>
				<br />
				<Grid item sx={{ display: 'flex', justifyContent: 'flex-start' }}>
					<CustomTable
						page="reseller/edit"
						tableData={tableData?.reseller}
						errorMessage={''}
						colheaders={colheaders}
						width={colheaders.length * 12}
						action={t('reseller_edit_datascroller_buttonedit')}
						handleDelete={(id) => {
							setSelectedRecord(id);
							setShowDailog(true);
						}}
					/>
				</Grid>
				<Grid item>
					<div className="pagination">
						{tableData?.length !== 0 && (
							<CustomPagination
								total={tableData.length}
								totalRecords={tableData?.totalNumberOfRecord}
								handlePageChange={handlePageChange}
								pageSize={pageSize}
								pageNo={pageNum}
								totalPages={tableData?.numberOfPages}
							/>
						)}
					</div>
				</Grid>
			</Grid>
			<p className="error-text">{t('footerresults')}</p>
			<br />
			<br />
			<ErrorToast
				open={openError}
				handleClose={handleCloseError}
				message={apiError}
			/>
			<SuccessToast
				open={openToast}
				message={t('requestSuccess')}
				handleClose={(event, reason) => {
					if (reason === 'clickaway') {
						return;
					}
					setOpenToast(false);
				}}
			/>
			<ConfirmDailog
				open={showDailog}
				closeDialog={() => {
					setShowDailog(false);
				}}
				heading={t('maintain_mpc_delete')}
				content={t('maintain_mpc_delete_message')}
				confirm={deleteReseller}
			/>
			<Loader open={resultLoading} text={'Loading'} />
		</div>
	);
};

export default EditReseller;
