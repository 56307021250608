import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Trash, Download } from 'react-feather';
import PropTypes from 'prop-types';
import pdfIcon from '../../Resources/images/pdf.png';
import defaultIcon from '../../Resources/images/default.png';

const FilePreviewCard = ({
	fileName,
	fileSize,
	onDeleteFile,
	fileKey,
	width,
	showDownload,
	onDownload,
}) => {
	const handleDelete = () => {
		onDeleteFile({ fileKey });
	};
	const handlDownload = () => {
		onDownload();
	};
	const lastIndex = fileName?.lastIndexOf('.');
	const fileType = fileName?.slice(lastIndex + 1).toUpperCase();

	let thumbnailImage;
	switch (fileType?.toUpperCase()) {
		case 'PDF':
			thumbnailImage = pdfIcon;
			break;
		default:
			thumbnailImage = defaultIcon;
	}
	return (
		<Card
			data-testid="filepreview-card"
			sx={{
				boxShadow: 'unset',
				borderRadius: ' 0.375rem',
				border: '1px solid var(--greys-grey-border, #E5E5E5)',
				padding: '0',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					height: 'auto',
					position: 'relative',
				}}
			>
				<CardContent
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						padding: 0,
						marginLeft: '1rem',
					}}
				>
					<div className="fileIcon" role="presentation">
						<img
							src={thumbnailImage}
							alt="preview"
							width="45px"
							height="45px"
						/>
					</div>
					<div className="fileDetails">
						<p
							style={{
								marginRight: '0',
								wordWrap: 'break-word',
								width: `${width}`,
							}}
						>
							{fileName}
						</p>
						<p style={{ color: '#C8C8C8' }}>{`${(
							fileSize /
							(1024 * 1024)
						).toFixed(2)} mb`}</p>
					</div>
				</CardContent>

				<CardActions sx={{ padding: 0, position: 'absolute', right: '0' }}>
					{showDownload === true && (
						<Button
							style={{ color: '#000000' }}
							sx={{ padding: 0 }}
							onClick={() => handlDownload()}
							data-testid="downloadbtn"
						>
							<Download width={'2rem'} height={'2rem'} />
						</Button>
					)}

					<Button
						style={{ color: '#000000' }}
						sx={{ padding: 0 }}
						onClick={() => handleDelete()}
						data-testid="trashbtn"
					>
						<Trash width={'2rem'} height={'2rem'} />
					</Button>
				</CardActions>
			</Box>
		</Card>
	);
};
FilePreviewCard.propTypes = {
	fileName: PropTypes.any,
	fileSize: PropTypes.any,
	onDeleteFile: PropTypes.any,
	fileKey: PropTypes.any,
};
export default FilePreviewCard;
