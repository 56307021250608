import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomTable from '../../CommonComponents/Table/CustomTable';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import './VerificationOfReseller.css';
import CustomDropDown from '../../CommonComponents/Dropdown/CustomDropDown';
import {
	getVerifyResellerCSV,
	getVerifyResellerExcel,
	getVerifyResellerList,
} from '../../Services/resellerServices';
import { getAllCountryListService } from '../../Services/getCountryListService';
import { roles } from '../../Constants/Permission';
import Loader from '../../CommonComponents/Loader/Loader';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import CustomPagination from '../../CommonComponents/Table/CustomPagination';

const VerificationOfReseller = () => {
	const options = [
		{ id: 1, name: 'XLS' },
		{ id: 2, name: 'CSV' },
	];
	const { t } = useTranslation();
	const [fileFormat, setFileFormat] = useState('XLS');
	const [allCountries, setAllCountries] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [pageNum, setPageNum] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [resultLoading, setResultLoading] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState('All');
	const [role, setRole] = useState(null);
	const [apiError, setApiError] = useState(null);
	const [openError, setOpenError] = useState(false);
	const [selectedCountryName, setSelectedCountryName] = useState('All');

	const user_info = useSelector((state) => state?.auth?.user);
	const currentRole = useSelector((state) => state?.role?.currentRole);
	const colheaders = [
		{
			key: 'name',
			name: t('companyName'),
		},
		{
			key: 'address',
			name: t('reseller_label_address'),
		},
		{
			key: 'alternativename',
			name: t('reseller_label_alternativename'),
		},
		{
			key: 'identifiyingcountryName',
			name: t('reseller_label_country'),
		},
		{
			key: 'remarks',
			name: t('reseller_label_remarks'),
		},
		{
			key: 'divisionName',
			name: t('reseller_label_divisionalfocus'),
		},
	];
	const handleSelectFileFormat = (value) => {
		setFileFormat(value);
	};
	const fetchData = async (pageNo) => {
		try {
			if (currentRole === roles.MPC) {
				let market =
					selectedCountry === 'All'
						? user_info?.countries
								.map((country) => country.countryisocode)
								.join(',')
						: selectedCountry;
				setResultLoading(true);
				getVerifyResellerList(market, pageNo, 10)
					.then((data) => {
						setTableData(data?.result);
						setResultLoading(false);
					})
					.catch(() => {
						console.error(err);
						setApiError(t('apiError'));
						setOpenError(true);
						setResultLoading(false);
					});
			} else if (currentRole === roles.HQ) {
				let market = selectedCountry === 'All' ? null : selectedCountry;
				setResultLoading(true);
				getVerifyResellerList(market, pageNo, 10)
					.then((data) => {
						setTableData(data?.result);
						setResultLoading(false);
					})
					.catch(() => {
						console.error(err);
						setApiError(t('apiError'));
						setOpenError(true);
						setResultLoading(false);
					});
			}
		} catch (err) {
			console.error(err);
			setApiError(t('apiError'));
			setOpenError(true);
			setResultLoading(false);
		}
	};
	useEffect(() => {
		getAllCountryListService()
			.then((countries) => {
				setAllCountries(countries?.result);
			})
			.catch((err) => {
				console.error(err);
				setApiError(t('apiError'));
				setOpenError(true);
				setAllCountries([]);
			});
	}, []);
	useEffect(() => {
		if (currentRole === roles.HQ) setRole('European');
	}, [currentRole]);

	useEffect(() => {
		if (user_info && currentRole) {
			fetchData(0);
		}
	}, [user_info, currentRole]);
	useEffect(() => {
		fetchData(0);
		setPageNum(0);
	}, [selectedCountry]);
	const handlePageChange = async (pageNo) => {
		setPageNum(pageNo - 1);
		fetchData(pageNo - 1);
	};
	const handleStartExport = async () => {
		try {
			if (fileFormat === 'XLS') {
				if (selectedCountry === 'All') {
					let market = null;
					if (currentRole === roles.MPC) {
						market = user_info?.countries.map(
							(country) => country.countryisocode
						);
						market = market.join(',');
					}
					setResultLoading(true);
					const result = await getVerifyResellerExcel(market);
					setResultLoading(false);
					if (result.status === 200) {
						saveAs(result.data, 'ResellerHistory.xlsx');
					} else {
						let error = new Error();
						throw error;
					}
				} else {
					setResultLoading(true);
					const result = await getVerifyResellerExcel(selectedCountry);
					setResultLoading(false);
					if (result.status === 200) {
						saveAs(result.data, 'ResellerHistory.xlsx');
					} else {
						let error = new Error();
						throw error;
					}
				}
			} else if (fileFormat === 'CSV') {
				if (selectedCountry === 'All') {
					let market = null;
					if (currentRole === roles.MPC) {
						market = user_info?.countries.map(
							(country) => country.countryisocode
						);
						market = market.join(',');
					}
					setResultLoading(true);
					const result = await getVerifyResellerCSV(market);
					setResultLoading(false);
					if (result.status === 200) {
						saveAs(result.data, 'ResellerHistory.csv');
					} else {
						let error = new Error();
						throw error;
					}
				} else {
					setResultLoading(true);
					const result = await getVerifyResellerCSV(selectedCountry);
					setResultLoading(false);
					if (result.status === 200) {
						saveAs(result.data, 'ResellerHistory.csv');
					} else {
						let error = new Error();
						throw error;
					}
				}
			}
		} catch (err) {
			console.error(err);
			setApiError(t('apiError'));
			setOpenError(true);
			setResultLoading(false);
		}
	};
	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	return (
		<div className="VerificationOfResellerWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				{t('navlevel1_9')}
			</Typography>
			<hr />
			<Grid container direction={'column'}>
				<Grid item>
					<Grid
						container
						direction={'row'}
						className="countryFileFormatWrapper"
					>
						<Grid item width="25%">
							<AutoCompleteCountryDropdown
								width="90%"
								height="2.5em"
								placeholder={
									selectedCountry?.length === 0
										? t('reseller_label_identifyingcountry')
										: ''
								}
								value={selectedCountryName}
								onChange={(newValue) => {
									if (newValue) {
										const country = allCountries.find(
											(country) => country.countryName === newValue
										);
										setSelectedCountryName(newValue);
										setSelectedCountry(country.countryisocode);
									} else {
										setSelectedCountry('All');
										setSelectedCountryName('All');
									}
								}}
								country={role}
								user={currentRole}
								countries={
									currentRole === roles.MPC ? user_info?.countries : []
								}
							/>
						</Grid>
						<Grid item width="15%">
							<CustomDropDown
								options={options}
								multiple={false}
								label="Select file format"
								id="role-dropdown"
								onChange={handleSelectFileFormat}
								width={'7em'}
								selectedValue={fileFormat}
								defaultValue={'XLS'}
								value={fileFormat}
							/>
						</Grid>

						<Grid item>
							<CustomButton
								text={t('export_label_startexport_button')}
								width="auto"
								handleClick={handleStartExport}
								disabled={tableData?.reseller?.length === 0}
							/>
						</Grid>
					</Grid>
				</Grid>
				<br />

				<Grid item>
					<CustomTable
						page="reseller/verification"
						tableData={tableData?.reseller}
						errorMessage={errorMessage}
						colheaders={colheaders}
						action={t('verify_reseller_button_verify')}
					/>
				</Grid>
				<Grid item>
					<div className="pagination">
						{tableData?.reseller?.length !== 0 && (
							<CustomPagination
								totalRecords={tableData?.totalNumberOfRecord}
								handlePageChange={handlePageChange}
								pageSize={pageSize}
								pageNo={pageNum}
								totalPages={tableData?.numberOfPages}
							/>
						)}
					</div>
				</Grid>
				<br />
				<p className="error-text">{t('footerresults')}</p>
				<ErrorToast
					open={openError}
					handleClose={handleCloseError}
					message={apiError}
				/>
				<Loader open={resultLoading} text={'Loading'} />
			</Grid>
			<br />
			<br />
		</div>
	);
};
export default VerificationOfReseller;
