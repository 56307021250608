import { useTranslation } from 'react-i18next';
import {
	createTheme,
	Dialog,
	DialogContent,
	ThemeProvider,
	Typography,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import CustomButton from '../Button/CustomButton';

const theme = createTheme({
	components: {
		Dialog: {
			root: {
				fontSize: '1rem',
				background: 'whitesmoke',
			},
		},
	},
});
const DataReviewDailog = (props) => {
	const { t } = useTranslation();
	return (
		<div>
			<ThemeProvider theme={theme}>
				<Dialog
					open
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					fontFamily="DaimlerCS-Regular"
					PaperProps={{
						style: {
							backgroundColor: '#fff',
							alignItems: 'left',
							width: '30rem',
							height: '12rem',
							maxWidth: '55rem',
							border: '1px solid',
							borderColor: '#E5E5E5',
							borderRadius: '0.875rem',
							background: '#FFFFFF',
							position: 'relative',
						},
					}}
				>
					<DialogContent>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								lineBreak: 'auto',
							}}
						>
							<Typography
								sx={{
									fontSize: '16px',
									fontWeight: 'bold',
									width: '90%',
									display: 'flex',
								}}
							>
								{t('data_review_label_reminder')}
							</Typography>
						</div>
						<br />
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<CustomButton
								width={'5rem'}
								height={'2rem'}
								text={'Ok'}
								handleClick={props?.closeDailog}
							/>
						</div>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</div>
	);
};
export default DataReviewDailog;
