import './Footer.css';

const Footer = () => {
	return (
		<div className="footerWrapper">
			<div className="footerLeftpart">
				<span className="marginleftten">Version: 5.0.0.0</span>
			</div>
			<div className="footerRightpart">
				<a
					href="https://www.daimlertruck.com/provider/"
					target="_blank"
					className="footer-common-link"
				>
					© 2022 Daimler Truck AG (Provider)
				</a>
				<div className="verticalLine"></div>
				<a
					href="https://www.daimlertruck.com/privacy/"
					target="_blank"
					className="footer-common-link"
				>
					Privacy Statement
				</a>
				<div className="verticalLine"></div>
				<a
					href="https://www.daimlertruck.com/provider/"
					target="_blank"
					className="footer-common-link"
				>
					Legal Statement
				</a>
				<div className="verticalLine"></div>
				<a
					href="https://xentry.mercedes-benz.com/information/content/display/partsexlist_dtag"
					target="_blank"
					className="marginrightten marginleftten verticalalignment footer-common-link"
				>
					Contact
				</a>
			</div>
		</div>
	);
};

export default Footer;
