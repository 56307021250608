import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomBarChart from '../../CommonComponents/BarChart/CustomBarChart';
import './KpiReport.css';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import { getCountryListService } from '../../Services/getCountryListService';
import Loader from '../../CommonComponents/Loader/Loader';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import { getKPIReport, exportKPI } from '../../Services/hqService';

const KpiReport = () => {
	const { t } = useTranslation();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(
		dayjs(new Date()).format('YYYY-MM-DD')
	);
	const [market, setMarket] = useState(null);
	const [marketName, setMarketName] = useState('All');
	const [allCountries, setAllCountries] = useState([]);
	const [resultLoading, setResultLoading] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [openError, setOpenError] = useState(false);
	const [data, setData] = useState([]);
	const maxDate = new Date();

	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	const handleStartExport = async () => {
		try {
			const result = await exportKPI(startDate, endDate, market);
			if (result.status === 200) {
				saveAs(result.data, `KPI_Export_CONFIDENTIAL.xlsx`);
			} else {
				let error = new Error();
				throw error;
			}
		} catch (err) {
			console.error(err);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	useEffect(() => {
		getCountryListService()
			.then((countries) => {
				setAllCountries(countries?.result);
			})
			.catch((err) => {
				console.error(err);
				setApiError(t('apiError'));
				setOpenError(true);
				setAllCountries([]);
			});
	}, []);
	useEffect(() => {
		getKPIReport(startDate, endDate, market)
			.then((data) => {
				setData(data.result);
			})
			.catch((err) => {
				console.error(err);
				setApiError(t('apiError'));
				setOpenError(true);
			});
	}, [startDate, endDate, market]);
	return (
		<div className="kpiReportWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				{t('navlevel1_11')}
			</Typography>
			<hr />
			<br />
			<Typography sx={{ paddingLeft: '2rem' }}>{t('kpi_message')}</Typography>
			<br />
			<Grid container direction={'column'} gap={2} sx={{ paddingLeft: '2rem' }}>
				<Grid item>
					<Grid container direction={'row'} columnGap={2}>
						<Grid item>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['DatePicker']}>
									<DatePicker
										slotProps={{
											textField: {
												size: 'small',
												placeholder: t('from'),
											},
										}}
										onChange={(value) => {
											setStartDate(dayjs(value).format('YYYY-MM-DD'));
										}}
										maxDate={dayjs(new Date())}
									/>
								</DemoContainer>
							</LocalizationProvider>
						</Grid>
						<Grid item>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['DatePicker']}>
									<DatePicker
										slotProps={{
											textField: {
												size: 'small',
												placeholder: t('to'),
											},
										}}
										onChange={(value) => {
											setEndDate(dayjs(value).format('YYYY-MM-DD'));
										}}
										maxDate={dayjs(new Date())}
									/>
								</DemoContainer>
							</LocalizationProvider>
						</Grid>
						<Grid item className="countryFileFormatWrapper" width={'25%'}>
							<AutoCompleteCountryDropdown
								width="90%"
								height="2.5em"
								placeholder={t('maintain_mpc_market')}
								value={marketName}
								onChange={(newValue) => {
									if (newValue) {
										const country = allCountries.find(
											(country) => country.countryName === newValue
										);
										setMarketName(newValue);
										setMarket(country.countryisocode);
									} else {
										setMarketName('All');
										setMarket(null);
									}
								}}
								country="European"
							/>
						</Grid>
						<Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
							<CustomButton
								text={t('export_label_startexport_button')}
								width="auto"
								handleClick={handleStartExport}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<CustomBarChart
						data={data}
						xLabel={t('usr_type')}
						yLabel={t('login_cnt')}
					/>
				</Grid>
			</Grid>
			<br />
			<br />
			<ErrorToast
				open={openError}
				handleClose={handleCloseError}
				message={apiError}
			/>
			<Loader open={resultLoading} text={'Loading'} />
		</div>
	);
};

export default KpiReport;
