import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomTable from '../../CommonComponents/Table/CustomTable';
import './MaintainContact.css';
import { getMpcByMarket, deleteContact } from '../../Services/mpcServices';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import SuccessToast from '../../CommonComponents/Toast/SuccessToast';
import ContactContext from './ContactContext';
import Loader from '../../CommonComponents/Loader/Loader';
import ConfirmDailog from '../../CommonComponents/Dailog/ConfirmDailog';
import CustomPagination from '../../CommonComponents/Table/CustomPagination';

const MaintainContactMPC = () => {
	const { t } = useTranslation();
	const user_info = useSelector((state) => state?.auth?.user);
	const [tableData, setTableData] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [pageNum, setPageNum] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [resultLoading, setResultLoading] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [openError, setOpenError] = useState(false);
	const [contacts, setContacts] = useState([]);
	const [openToast, setOpenToast] = useState(false);
	const [reload, setReload] = useState(false);
	const [showDailog, setShowDailog] = useState(false);

	const colheaders = [
		{
			key: 'userId',
			name: t('maintain_mpc_userID'),
		},
		{
			key: 'name',
			name: t('maintain_mpc_mpcName'),
		},
		{
			key: 'email',
			name: t('maintain_mpc_email'),
		},
		{
			key: 'countryname',
			name: t('maintain_mpc_market'),
		},
		{
			key: 'divisionName',
			name: t('maintain_mpc_Division'),
		},
	];

	useEffect(() => {
		const countryCode = user_info?.countries.map(
			(country) => country?.countryisocode
		);
		const countries = countryCode?.length ? countryCode.toString() : null;
		setResultLoading(true);
		if (countries) {
			getMpcByMarket(countries, pageNum, pageSize)
				.then((data) => {
					setResultLoading(false);
					setTableData(data?.result);
				})
				.catch(() => {
					setResultLoading(false);
					setApiError(t('apiError'));
					setOpenError(true);
				});
		}
	}, [user_info, reload]);
	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	const handleDelete = async () => {
		try {
			let selectedContacts = contacts.join(',');
			setShowDailog(false);
			setResultLoading(true);
			const result = await deleteContact(selectedContacts);
			if (result) {
				setContacts([]);
				setOpenToast(true);
				setReload(!reload);
			} else {
				throw new Error();
			}
		} catch (err) {
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const handlePageChange = async (pageNo) => {
		try {
			const countryCode = user_info?.countries.map(
				(country) => country?.countryisocode
			);
			const countries = countryCode?.length ? countryCode.toString() : null;
			setPageNum(pageNo - 1);
			setResultLoading(true);
			const data = await getMpcByMarket(countries, pageNo - 1, 10);
			setResultLoading(false);
			setTableData(data?.result);
		} catch (err) {
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	return (
		<ContactContext.Provider value={{ contacts, setContacts }}>
			<div className="maintainContactWrapper">
				<Typography
					sx={{
						color: '#151C2D',
						fontSize: '14px',
						fontStyle: 'normal',
						fontWeight: 600,
						fontFamily: 'Arial, Helvetica, sans-serif',
					}}
				>
					{t('navlevel1_10')}
				</Typography>
				<hr />
				<br />
				<Grid container direction={'column'} rowGap={3}>
					<Grid item>
						{/* Grid holding the buttons */}
						<Grid container direction={'row'} columnGap={3}>
							<Grid item>
								<Link to="/contact/add">
									<CustomButton
										width={'auto'}
										height={'2rem'}
										text={t('maintain_mpc_add')}
									/>
								</Link>
							</Grid>
							<Grid item>
								<CustomButton
									width={'auto'}
									height={'2rem'}
									text={t('maintain_mpc_delete')}
									handleClick={() => {
										setShowDailog(true);
									}}
									disabled={contacts.length === 0}
								/>
							</Grid>
						</Grid>
					</Grid>
					{/* Grid holding the table */}
					<Grid item sx={{ display: 'flex', justifyContent: 'flex-start' }}>
						<CustomTable
							page="contact/edit"
							tableData={tableData?.mpc}
							errorMessage={errorMessage}
							colheaders={colheaders}
							width={colheaders.length * 12}
							action={t('reseller_edit_datascroller_buttonedit')}
						/>
					</Grid>
					<Grid item>
						<CustomPagination
							totalRecords={tableData?.totalNumberOfRecord}
							handlePageChange={handlePageChange}
							pageSize={pageSize}
							pageNo={pageNum}
							totalPages={tableData?.numberOfPages}
						/>
					</Grid>
				</Grid>
				<Loader open={resultLoading} text={'Loading'} />
				<ErrorToast
					open={openError}
					handleClose={handleCloseError}
					message={apiError}
				/>
				<SuccessToast
					open={openToast}
					message={t('requestSuccess')}
					handleClose={(event, reason) => {
						if (reason === 'clickaway') {
							return;
						}
						setOpenToast(false);
					}}
				/>
				<ConfirmDailog
					open={showDailog}
					closeDialog={() => {
						setShowDailog(false);
					}}
					heading={t('maintain_mpc_delete')}
					content={t('maintain_mpc_delete_message')}
					confirm={handleDelete}
				/>
			</div>
		</ContactContext.Provider>
	);
};
export default MaintainContactMPC;
