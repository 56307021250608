import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Header from './Modules/Header/Header';
import Home from './Modules/Home/Home';
import AddNewReseller from './Modules/AddNewReseller/AddNewReseller';
import SearchReseller from './Modules/SearchReseller/SearchReseller';
import VerificationOfReseller from './Modules/VerificationOfReseller/VerificationOfReseller';
import VerificationForm from './Modules/VerificationOfReseller/VerificationForm';
import EditReseller from './Modules/EditReseller/EditReseller';
import ExportData from './Modules/ExportData/ExportData';
import DataReview from './Modules/DataReview/DataReview';
import MaintainContact from './Modules/MaintainContact/MaintainContact';
import KPIReport from './Modules/KPIReport/KpiReport';
import Download from './Modules/Download/Download';
import Help from './Modules/Help/Help';
import EditResellerForm from './Modules/EditReseller/EditResellerForm';
import MaintainContactMPC from './Modules/MaintainContact/MaintainContactMPC';
import SearchContact from './Modules/MaintainContact/SearchContact';
import AddContact from './Modules/MaintainContact/AddContact';
import EditContact from './Modules/MaintainContact/EditContact';

const Router = () => {
	const { t } = useTranslation();
	const currentRole = useSelector((state) => state?.role?.currentRole);

	return (
		<div>
			<Header />
			<div className="content">
				<p className="top-content-text">{t('welcomeText')}</p>
				<Routes>
					<Route path="/partsexl" element={<Home />} />
					<Route path="/" element={<Navigate to="/partsexl" replace />} />
					<Route path="/search-reseller" element={<SearchReseller />} />

					<Route path="/add-new-reseller" element={<AddNewReseller />} />
					<Route
						path="/reseller/verification/:id"
						element={<VerificationForm />}
					/>
					<Route
						path="/verification-of-reseller"
						element={<VerificationOfReseller />}
					/>
					<Route path="/reseller/edit/:id" element={<EditResellerForm />} />
					<Route path="/edit-reseller" element={<EditReseller />} />

					<Route path="/export-data" element={<ExportData />} />
					<Route path="/data-review" element={<DataReview />} />
					<Route
						path="/maintain-contact-MPC"
						element={<MaintainContactMPC />}
					/>
					<Route path="/contact/add" element={<AddContact />} />
					<Route path="/contact/edit/:id" element={<EditContact />} />
					<Route path="/maintain-contact" element={<MaintainContact />} />

					<Route path="/search-contact" element={<SearchContact />} />
					<Route path="/KPI-report" element={<KPIReport />} />
					<Route path="/download/*" element={<Download />} />
					<Route path="/help" element={<Help />} />
				</Routes>
			</div>
		</div>
	);
};

export default Router;
