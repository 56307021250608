import { Pagination, Stack } from '@mui/material';

const CustomPagination = (props) => {
	const handleChange = (__, pageNum) => {
		props?.handlePageChange(pageNum);
	};
	return (
		<Stack spacing={3}>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<Pagination
					showFirstButton
					showLastButton
					onChange={handleChange}
					count={Math.ceil(props?.totalRecords / props?.pageSize || 10)}
				/>
				<span
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						fontWeight: 600,
						fontSize: '.875rem',
						fontStyle: 'normal',
						fontFamily: 'Arial, Helvetica, sans-serif',
					}}
				>{`Page ${props?.pageNo + 1} of ${props?.totalPages}`}</span>
			</div>
		</Stack>
	);
};

export default CustomPagination;
