import { createSlice } from '@reduxjs/toolkit';

const languageSlice = createSlice({
	name: 'languages',
	initialState: {
		languages: null,
		currentLanguage: localStorage.getItem('language') || 'en',
		languagedropwn: false,
	},
	reducers: {
		setLanguages: (state, action) => {
			state.languages = action.payload;
		},
		setcurrentLanguage: (state, action) => {
			state.currentLanguage = action.payload;

			localStorage.setItem('language', action.payload);
		},
		setLanguageDropdown: (state, action) => {
			state.languagedropwn = action.payload;
		},
	},
});
export const { setLanguages, setcurrentLanguage, setLanguageDropdown } =
	languageSlice.actions;
export default languageSlice.reducer;
