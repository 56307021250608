import { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography, Grid, Checkbox } from '@mui/material';
import './DataReview.css';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import { getCountryListService } from '../../Services/getCountryListService';
import { roles } from '../../Constants/Permission';
import Loader from '../../CommonComponents/Loader/Loader';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import { dataReviewHQ, getDataReviewExcelHQ } from '../../Services/hqService';
import { dataReviewMPC } from '../../Services/mpcServices';
import { exportXLSData } from '../../Services/resellerServices';
import SuccessToast from '../../CommonComponents/Toast/SuccessToast';

const DataReview = () => {
	const { t } = useTranslation();
	const checkBoxlabel = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedCountryName, setSelectedAllCountryName] = useState('All');
	const [role, setRole] = useState(null);
	const [division, setDivision] = useState([]);
	const [fieldErrors, setFieldErrors] = useState({});
	const [resultLoading, setResultLoading] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [openError, setOpenError] = useState(false);
	const [allCountries, setAllCountries] = useState([]);
	const [openToast, setOpenToast] = useState(false);

	const user_info = useSelector((state) => state?.auth?.user);
	const currentRole = useSelector((state) => state?.role?.currentRole);
	useEffect(() => {
		getCountryListService()
			.then((countries) => {
				setAllCountries(countries?.result);
			})
			.catch((err) => {
				console.error(err);
				setApiError(t('apiError'));
				setOpenError(true);
				setAllCountries([]);
			});
	}, []);
	useEffect(() => {
		if (currentRole === roles.HQ) setRole('European');
	}, [currentRole]);
	const handleStartExport = async () => {
		try {
			if (currentRole === roles.HQ) {
				setResultLoading(true);
				const result = await getDataReviewExcelHQ(selectedCountry);
				setResultLoading(false);
				if (result.status === 200) {
					saveAs(
						result.data,
						`PARTSEXL_Export_${user_info.userName}${Math.random()}_CONFIDENTIAL.xlsx`
					);
				} else {
					let error = new Error();
					throw error;
				}
			} else {
				if (selectedCountryName === 'All') {
					let selectedCountries = user_info?.countries?.map(
						(country) => country.countryisocode
					);
					selectedCountries = selectedCountries.join(',');
					setResultLoading(true);
					const result = await exportXLSData(selectedCountries);
					setResultLoading(false);
					if (result.status === 200) {
						saveAs(
							result.data,
							`PARTSEXL_Export_${user_info.userName}${Math.random()}_CONFIDENTIAL.xlsx`
						);
					} else {
						let error = new Error();
						throw error;
					}
				} else {
					setResultLoading(true);
					const result = await exportXLSData(selectedCountry);
					setResultLoading(false);
					if (result.status === 200) {
						saveAs(
							result.data,
							`PARTSEXL_Export_${user_info.userName}${Math.random()}_CONFIDENTIAL.xlsx`
						);
					} else {
						let error = new Error();
						throw error;
					}
				}
			}
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const handleSubmit = async () => {
		try {
			if (division.length === 0) {
				setFieldErrors((prevErr) => ({
					...prevErr,
					division: t('data_review_select_division_alert'),
				}));
			} else {
				const divisionString = division.join(',');
				if (currentRole === roles.HQ) {
					setResultLoading(true);
					const result = await dataReviewHQ(selectedCountry, divisionString);
					setResultLoading(false);
					if (result) {
						setOpenToast(true);
					} else {
						throw new Error();
					}
				} else if (currentRole === roles.MPC) {
					if (selectedCountry === null) {
						let selectedCountries = user_info?.countries?.map(
							(country) => country.countryisocode
						);
						selectedCountries = selectedCountries.join(',');
						setResultLoading(true);
						const result = await dataReviewMPC(
							selectedCountries,
							divisionString
						);
						setResultLoading(false);
						if (result) {
							setOpenToast(true);
						} else {
							throw new Error();
						}
					} else {
						setResultLoading(true);
						const result = await dataReviewMPC(selectedCountry, divisionString);
						setResultLoading(false);
						if (result) {
							setOpenToast(true);
						} else {
							throw new Error();
						}
					}
				}
			}
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	return (
		<div className="DataReviewWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				{t('navlevel1_7')}
			</Typography>
			<hr />
			<Grid container flexDirection={'column'}>
				<Grid item className="countryFileFormatWrapper">
					<Grid item width="100%">
						<p className="dataReviewText">
							{t('data_review_label_process_header')}
						</p>
					</Grid>
					<Grid item width="25%">
						<AutoCompleteCountryDropdown
							width="90%"
							height="2.5em"
							placeholder="Identifying Country"
							value={selectedCountryName}
							onChange={(newValue) => {
								if (newValue) {
									const country = allCountries.find(
										(country) => country.countryName === newValue
									);
									setSelectedCountry(country.countryisocode);
									setSelectedAllCountryName(newValue);
								} else {
									setSelectedAllCountryName('All');
									setSelectedCountry(null);
								}
							}}
							country={role}
							user={currentRole}
							countries={currentRole === roles.MPC ? user_info?.countries : []}
						/>
					</Grid>
				</Grid>
				<Grid item className="TextContainer">
					<p className="dataReviewText">{t('data_review_label_process_1')}</p>
					<CustomButton
						text={t('export_label_startexport_button')}
						width="auto"
						marginLeft="15px"
						handleClick={handleStartExport}
					/>

					<br />
					<br />
					<p className="dataReviewText">{t('data_review_label_process_2')}</p>
					<p className="dataReviewText">{t('data_review_label_process_3')}</p>
					<p className="dataReviewText">{t('data_review_label_process_4')}</p>

					<Grid item className="checkBoxBlock">
						<div className="checkBoxBtn">
							<Checkbox
								{...checkBoxlabel}
								onChange={(e) => {
									if (e.target.checked) {
										setFieldErrors((prevError) => {
											const updatedErrors = { ...prevError };
											delete updatedErrors['division'];
											return updatedErrors;
										});
										setDivision([...division, 'T']);
									} else {
										const filteredDivision = division.filter(
											(div) => div !== 'T'
										);
										if (filteredDivision.length === 0) {
											setFieldErrors((prevErr) => ({
												...prevErr,
												division: t('data_review_select_division_alert'),
											}));
										}
										setDivision(filteredDivision);
									}
								}}
							/>
						</div>
						<p className="dataReviewText">
							{`Truck: ${t('data_review_label_process_5')}`}
						</p>

						<br />
						<div className="checkBoxBtn">
							<Checkbox
								{...checkBoxlabel}
								onChange={(e) => {
									if (e.target.checked) {
										setFieldErrors((prevError) => {
											const updatedErrors = { ...prevError };
											delete updatedErrors['division'];
											return updatedErrors;
										});
										setDivision([...division, 'B']);
									} else {
										const filteredDivision = division.filter(
											(div) => div !== 'B'
										);
										if (filteredDivision.length === 0) {
											setFieldErrors((prevErr) => ({
												...prevErr,
												division: t('data_review_select_division_alert'),
											}));
										}
										setDivision(filteredDivision);
									}
								}}
							/>
						</div>

						<p className="dataReviewText">
							{`Bus: ${t('data_review_label_process_5')}`}
						</p>
					</Grid>
					{'division' in fieldErrors && (
						<div style={{ color: 'red' }} className="error">
							{t('data_review_select_division_alert')}
						</div>
					)}
				</Grid>
				<br />
				<CustomButton
					text={t('data_review_button_confirm')}
					width="8rem"
					handleClick={handleSubmit}
					disabled={division.length === 0}
				/>
			</Grid>
			<ErrorToast
				open={openError}
				handleClose={handleCloseError}
				message={apiError}
			/>
			<SuccessToast
				open={openToast}
				message={t('requestSuccess')}
				handleClose={(event, reason) => {
					if (reason === 'clickaway') {
						return;
					}
					setOpenToast(false);
				}}
			/>
			<Loader open={resultLoading} text={'Loading'} />
		</div>
	);
};

export default DataReview;
