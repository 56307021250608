import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import DownloadSection from './DownloadSection';
import './Download.css';

const Download = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const isSectionSelected = location.pathname.includes('/download/');

	const downloadOptions = [
		{
			label: t('TemplatesProcessinstruction'),
			path: 'PIF',
		},
		{ label: t('TemplatesSampleLetter'), path: 'SLD' },
		{ label: t('TemplatesDeclarationFleet'), path: 'DUF' },
		{
			label: t('TemplatesDeclarationReuser'),
			path: 'URU',
		},
		{ label: t('TemplatesBodyBuilders'), path: 'BOB' },
		{ label: t('TemplatesReporting'), path: 'new reseller tamplet' },
		{ label: t('TemplatesFAQ'), path: 'FAQ' },
	];

	return (
		<div className="DownloadWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				{t('navlevel1_2')}
			</Typography>
			<hr />
			{!isSectionSelected && (
				<Grid container flexDirection={'column'} className="listOfDoc">
					{downloadOptions.map((option) => (
						<Grid item key={option.path}>
							<Typography
								noWrap
								sx={{
									color: '#151C2D',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: 600,
									fontFamily: 'Arial, Helvetica, sans-serif',
								}}
							>
								<Link className="downloadLink" to={`/download/${option.path}`}>
									{option.label}
								</Link>
							</Typography>
						</Grid>
					))}
				</Grid>
			)}

			<Routes>
				<Route path=":section" element={<DownloadSection />} />
			</Routes>
		</div>
	);
};

export default Download;
