import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import {
	Typography,
	Grid,
	Checkbox,
	FormControl,
	OutlinedInput,
} from '@mui/material';
import './ExportData.css';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomDropDown from '../../CommonComponents/Dropdown/CustomDropDown';
import { exportXLSData, exportCSVData } from '../../Services/resellerServices';
import { roles } from '../../Constants/Permission';
import Loader from '../../CommonComponents/Loader/Loader';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import { getCountryListService } from '../../Services/getCountryListService';

const ExportData = () => {
	const checkBoxlabel = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const options = [
		{ id: 1, name: 'XLS' },
		{ id: 2, name: 'CSV' },
	];
	const { t } = useTranslation();
	const [role, setRole] = useState(null);
	const [fileFormat, setFileFormat] = useState('XLS');
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [allCountries, setAllCountries] = useState([]);
	const [readInfo, setReadInfo] = useState(false);
	const [resultLoading, setResultLoading] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [openError, setOpenError] = useState(false);
	const [selectedCountryName, setSelectedCountryName] = useState('All');

	const user_info = useSelector((state) => state?.auth?.user);
	const currentRole = useSelector((state) => state?.role?.currentRole);

	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	useEffect(() => {
		getCountryListService()
			.then((countries) => {
				setAllCountries(countries?.result);
			})
			.catch((err) => {
				console.error(err);
				setApiError(t('apiError'));
				setOpenError(true);
				setAllCountries([]);
			});
	}, []);
	useEffect(() => {
		if (currentRole === roles.HQ) setRole('European');
	}, [currentRole]);
	const handleSelectFileFormat = (format) => {
		setFileFormat(format);
	};
	const handleReadInfo = () => {
		setReadInfo(!readInfo);
	};
	const handleStartExport = async () => {
		try {
			if (fileFormat === 'XLS') {
				if (currentRole === roles.HQ) {
					if (selectedCountry === 'All') {
						setResultLoading(true);
						const result = await exportXLSData();
						setResultLoading(false);
						if (result.status === 200) {
							saveAs(
								result.data,
								`PARTSEXL_Export_${user_info.userName}${Math.random()}_CONFIDENTIAL.xlsx`
							);
						} else {
							let error = new Error();
							throw error;
						}
					} else {
						setResultLoading(true);
						const result = await exportXLSData(selectedCountry);
						setResultLoading(false);
						if (result.status === 200) {
							saveAs(
								result.data,
								`PARTSEXL_Export_${user_info.userName}${Math.random()}_CONFIDENTIAL.xlsx`
							);
						} else {
							let error = new Error();
							throw error;
						}
					}
				} else {
					if (selectedCountry === 'All') {
						let selectedCountries = user_info?.countries?.map(
							(country) => country.countryisocode
						);
						selectedCountries = selectedCountries.join(',');
						setResultLoading(true);
						const result = await exportXLSData(selectedCountries);
						setResultLoading(false);
						if (result.status === 200) {
							saveAs(
								result.data,
								`PARTSEXL_Export_${user_info.userName}${Math.random()}_CONFIDENTIAL.xlsx`
							);
						} else {
							let error = new Error();
							throw error;
						}
					} else {
						setResultLoading(true);
						const result = await exportXLSData(selectedCountry);
						setResultLoading(false);
						if (result.status === 200) {
							saveAs(
								result.data,
								`PARTSEXL_Export_${user_info.userName}${Math.random()}_CONFIDENTIAL.xlsx`
							);
						} else {
							let error = new Error();
							throw error;
						}
					}
				}
			} else if (fileFormat === 'CSV') {
				if (currentRole === roles.HQ) {
					if (selectedCountry === 'All') {
						setResultLoading(true);
						const result = await exportCSVData();
						setResultLoading(false);
						if (result.status === 200) {
							saveAs(
								result.data,
								`PARTSEXL_Export_${user_info.userName}${Math.random()}_CONFIDENTIAL.csv`
							);
						} else {
							let error = new Error();
							throw error;
						}
					} else {
						setResultLoading(true);
						const result = await exportCSVData(selectedCountry);
						setResultLoading(false);
						if (result.status === 200) {
							saveAs(
								result.data,
								`PARTSEXL_Export_${user_info.userName}${Math.random()}_CONFIDENTIAL.csv`
							);
						} else {
							let error = new Error();
							throw error;
						}
					}
				} else {
					if (selectedCountry === 'All') {
						let selectedCountries = user_info?.countries?.map(
							(country) => country.countryisocode
						);
						selectedCountries = selectedCountries.join(',');
						setResultLoading(true);
						const result = await exportCSVData(selectedCountries);
						setResultLoading(false);
						if (result.status === 200) {
							saveAs(
								result.data,
								`PARTSEXL_Export_${user_info.userName}${Math.random()}_CONFIDENTIAL.csv`
							);
						} else {
							let error = new Error();
							throw error;
						}
					} else {
						setResultLoading(true);
						const result = await exportCSVData(selectedCountry);
						setResultLoading(false);
						if (result.status === 200) {
							saveAs(
								result.data,
								`PARTSEXL_Export_${user_info.userName}${Math.random()}_CONFIDENTIAL.csv`
							);
						} else {
							let error = new Error();
							throw error;
						}
					}
				}
			}
		} catch (err) {
			console.error(err);
			setApiError(t('apiError'));
			setOpenError(true);
			setResultLoading(false);
		}
	};
	return (
		<div className="ExportDataWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				{t('navlevel1_4')}
			</Typography>
			<hr />
			<Grid container flexDirection={'column'}>
				<Grid item className="countryFileFormatWrapper">
					<Grid item width="25%">
						<AutoCompleteCountryDropdown
							width="90%"
							height="2.5em"
							placeholder={
								selectedCountry?.length === 0
									? t('reseller_label_identifyingcountry')
									: ''
							}
							value={selectedCountryName}
							onChange={(newValue) => {
								if (newValue) {
									const country = allCountries.find(
										(country) => country.countryName === newValue
									);
									setSelectedCountryName(newValue);
									setSelectedCountry(country.countryisocode);
								} else {
									setSelectedCountry(null);
									setSelectedCountryName('All');
								}
							}}
							country={role}
							user={currentRole}
							countries={currentRole === roles.MPC ? user_info?.countries : []}
						/>
					</Grid>
					<Grid item width="15%">
						<CustomDropDown
							options={options}
							multiple={false}
							label="Select file format"
							id="role-dropdown"
							onChange={handleSelectFileFormat}
							width={'7em'}
							selectedValue={fileFormat}
							defaultValue={'XLS'}
							value={fileFormat}
						/>
					</Grid>
				</Grid>

				<br />
				<Grid item className="textBox">
					<Typography
						sx={{
							fontWeight: 600,
							paddingBottom: '10px',
						}}
					>
						{t('export_text_securityinformation1')}
					</Typography>
					<FormControl sx={{ width: '100%' }}>
						<OutlinedInput
							value={t('export_text_securityinformation2')}
							multiline
							rows={3}
						/>
					</FormControl>
				</Grid>
				<br />
				<br />
				<Grid item className="bottomSection" flexDirection={'row'}>
					<Grid item className="checkBoxText">
						<Checkbox
							{...checkBoxlabel}
							checked={readInfo}
							onClick={handleReadInfo}
						/>

						<Typography>{t('export_label_security_accept')}</Typography>
					</Grid>
					{readInfo && (
						<Grid item>
							<CustomButton
								text={t('export_label_startexport_button')}
								width="8em"
								handleClick={handleStartExport}
							/>
						</Grid>
					)}
				</Grid>
				<ErrorToast
					open={openError}
					handleClose={handleCloseError}
					message={apiError}
				/>
				<Loader open={resultLoading} text={'Loading'} />
			</Grid>
		</div>
	);
};

export default ExportData;
