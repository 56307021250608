import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomDropDown from '../../CommonComponents/Dropdown/CustomDropDown';
import Loader from '../../CommonComponents/Loader/Loader';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import CustomTable from '../../CommonComponents/Table/CustomTable';
import {
	getMpcByMarket,
	getMpcExcel,
	getMpcCsv,
} from '../../Services/mpcServices';
import { getCountryListService } from '../../Services/getCountryListService';
import CustomPagination from '../../CommonComponents/Table/CustomPagination';
import './MaintainContact.css';

const SearchContact = () => {
	const options = [
		{ id: 1, name: 'XLS' },
		{ id: 2, name: 'CSV' },
	];
	const { t } = useTranslation();
	const [fileFormat, setFileFormat] = useState('XLS');
	const [selectedCountry, setSelectedCountry] = useState('All');
	const [resultLoading, setResultLoading] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [openError, setOpenError] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [pageNum, setPageNum] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [allCountries, setAllCountries] = useState([]);

	const user_info = useSelector((state) => state?.auth?.user);
	const colheaders = [
		{
			key: 'userId',
			name: t('maintain_mpc_userID'),
		},
		{
			key: 'name',
			name: t('maintain_mpc_mpcName'),
		},
		{
			key: 'email',
			name: t('maintain_mpc_email'),
		},
		{
			key: 'countryname',
			name: t('maintain_mpc_market'),
		},
		{
			key: 'divisionName',
			name: t('maintain_mpc_Division'),
		},
	];

	useEffect(() => {
		setResultLoading(true);
		getCountryListService()
			.then((countries) => {
				setAllCountries(countries?.result);
			})
			.catch(() => {
				setResultLoading(false);
				setApiError(t('apiError'));
				setOpenError(true);
			});
		getMpcByMarket(null, 0, 10)
			.then((data) => {
				setResultLoading(false);
				setTableData(data?.result);
			})
			.catch(() => {
				setResultLoading(false);
				setApiError(t('apiError'));
				setOpenError(true);
			});
	}, []);
	const handleCountryChange = async (newValue) => {
		try {
			if (newValue) {
				setSelectedCountry(newValue);
				const country = allCountries.find(
					(country) => country.countryName === newValue
				);
				setResultLoading(true);
				let data = await getMpcByMarket(country.countryisocode, 0, 10);
				setPageNum(0);
				setResultLoading(false);
				setTableData(data?.result);
			} else {
				setSelectedCountry('All');
				setResultLoading(true);
				let data = await getMpcByMarket(null, 0, 10);
				setPageNum(0);
				setResultLoading(false);
				setTableData(data?.result);
			}
		} catch (err) {
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const handlePageChange = async (pageNo) => {
		try {
			const countryCode = allCountries.map(
				(country) => country?.countryisocode
			);
			const countries = countryCode?.length ? countryCode.toString() : null;
			setPageNum(pageNo - 1);
			setResultLoading(true);
			const data = await getMpcByMarket(countries, pageNo - 1, 10);
			setResultLoading(false);
			setTableData(data?.result);
		} catch (err) {
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const handleStartExport = async () => {
		try {
			if (fileFormat === 'XLS') {
				if (selectedCountry === 'All') {
					setResultLoading(true);
					const result = await getMpcExcel();
					setResultLoading(false);
					if (result.status === 200) {
						saveAs(
							result.data,
							`PARTSEXL_MPCDATA_Export${Math.random()}${user_info.userName}_CONFIDENTIAL.xlsx`
						);
					} else {
						let error = new Error();
						throw error;
					}
				} else {
					const country = allCountries.find(
						(country) => country.countryName === selectedCountry
					);
					setResultLoading(true);
					const result = await getMpcExcel(country?.countryisocode);
					setResultLoading(false);
					if (result.status === 200) {
						saveAs(
							result.data,
							`PARTSEXL_MPCDATA_Export${Math.random()}${user_info.userName}_CONFIDENTIAL.xlsx`
						);
					} else {
						let error = new Error();
						throw error;
					}
				}
			} else {
				if (selectedCountry === 'All') {
					const result = await getMpcCsv();
					setResultLoading(false);
					if (result.status === 200) {
						saveAs(
							result.data,
							`PARTSEXL_MPCDATA_Export${Math.random()}${user_info.userName}_CONFIDENTIAL.csv`
						);
					} else {
						let error = new Error();
						throw error;
					}
				} else {
					const country = allCountries.find(
						(country) => country.countryName === selectedCountry
					);
					setResultLoading(true);
					const result = await getMpcCsv(country?.countryisocode);
					setResultLoading(false);
					if (result.status === 200) {
						saveAs(
							result.data,
							`PARTSEXL_MPCDATA_Export${Math.random()}${user_info.userName}_CONFIDENTIAL.csv`
						);
					} else {
						let error = new Error();
						throw error;
					}
				}
			}
		} catch (err) {
			console.error(err);
			setApiError(t('apiError'));
			setOpenError(true);
			setResultLoading(false);
		}
	};
	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	const handleSelectFileFormat = (format) => {
		setFileFormat(format);
	};

	return (
		<div className="searchContactWrapper">
			<Typography
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				{t('navlevel1_21')}
			</Typography>
			<hr />
			<br />
			<Grid container flexDirection={'column'} rowGap={3}>
				<Grid item className="countryFileFormatWrapper">
					<Grid item width="25%">
						<AutoCompleteCountryDropdown
							width="90%"
							height="2.5em"
							placeholder={
								selectedCountry?.length === 0
									? t('reseller_label_identifyingcountry')
									: ''
							}
							value={selectedCountry}
							onChange={handleCountryChange}
							country="European"
						/>
					</Grid>
					<Grid item width="15%">
						<CustomDropDown
							options={options}
							multiple={false}
							label="Select file format"
							id="role-dropdown"
							onChange={handleSelectFileFormat}
							width={'7em'}
							selectedValue={fileFormat}
							defaultValue={'XLS'}
							value={fileFormat}
						/>
					</Grid>
					<Grid item>
						<CustomButton
							text={t('export_label_startexport_button')}
							width="8em"
							handleClick={handleStartExport}
							disabled={tableData?.mpc?.length === 0}
						/>
					</Grid>
				</Grid>
				<Grid item>
					<CustomTable
						page={null}
						tableData={tableData?.mpc}
						errorMessage={''}
						colheaders={colheaders}
						width={colheaders.length * 12}
					/>
				</Grid>
				<Grid item>
					<CustomPagination
						totalRecords={tableData?.totalNumberOfRecord}
						handlePageChange={handlePageChange}
						pageSize={pageSize}
						pageNo={pageNum}
						totalPages={tableData?.numberOfPages}
					/>
				</Grid>
			</Grid>
			<Loader open={resultLoading} text={'Loading'} />
			<ErrorToast
				open={openError}
				handleClose={handleCloseError}
				message={apiError}
			/>
		</div>
	);
};
export default SearchContact;
