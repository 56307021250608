import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		backend: {
			loadPath: function (lng) {
				const currentPath = window.location.pathname;
				console.log(lng);
				let basepath;
				if (currentPath.includes('/home/')) {
					basepath = '/';
				} else if (currentPath.includes('/search-reseller/')) {
					basepath = '/search-reseller';
				} else if (currentPath.includes('/add-new-reseller/')) {
					basepath = '/add-new-reseller';
				} else if (currentPath.includes('/verification-of-reseller/')) {
					basepath = '/verification-of-reseller';
				} else if (currentPath.includes('/edit-reseller/')) {
					basepath = '/edit-reseller';
				} else if (currentPath.includes('/export-data/')) {
					basepath = '/export-data';
				} else if (currentPath.includes('/data-review/')) {
					basepath = '/data-review';
				} else if (currentPath.includes('/maintain-contact-MPC/')) {
					basepath = '/maintain-contact-MPC';
				} else if (currentPath.includes('/maintain-contact/')) {
					basepath = '/maintain-contact';
				} else if (currentPath.includes('/search-contact/')) {
					basepath = '/search-contact';
				} else if (currentPath.includes('/KPI-report/')) {
					basepath = '/KPI-report';
				} else if (currentPath.includes('/download/')) {
					basepath = '';
				} else if (currentPath.includes('/help/')) {
					basepath = '/help';
				} else {
					basepath = '';
				}
				console.log(`${basepath}/locales/${lng}.json`)
				return `${basepath}/locales/${lng}.json`;
			},
		},

		fallbackLng: 'English', // Default language
		debug: true,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
