import axios from 'axios';
import { baseURL, mpc } from '../Constants/APIUrls';

export const getMpcByMarket = async (Market, pageNo, pageSize) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${mpc.getContactList}`,
			{
				params: {
					Market,
					pageNo,
					pageSize,
				},
			}
		);
		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const addMPC = async (values) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.post(
			`${baseURL}${mpc.addContact}`,
			values
		);

		return response.status;
	} catch (err) {
		console.error(err);
		if (err.response.status === 409) {
			return err.response.status;
		} else throw new Error(err);
	}
};

export const deleteContact = async (Id) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.delete(
			`${baseURL}${mpc.deleteContact}`,
			{
				params: {
					Id,
				},
			}
		);
		return response.status === 200;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const getContactById = async (Id) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${mpc.getContactById}`,
			{
				params: {
					Id,
				},
			}
		);
		return response.data;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const editContact = async (values) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.post(
			`${baseURL}${mpc.editContact}`,
			values
		);

		return response.status;
	} catch (err) {
		console.error(err);
		if (err.response.status === 409) {
			return err.response.status;
		} else throw new Error(err);
	}
};
export const getMpcExcel = async (Market) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(`${baseURL}${mpc.getMpcExcel}`, {
			params: {
				Market,
			},
			responseType: 'blob',
		});
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const getMpcCsv = async (Market) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(`${baseURL}${mpc.getMpcCsv}`, {
			params: {
				Market,
			},
			responseType: 'blob',
		});
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const dataReviewMPC = async (Markets, Division) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.post(
			`${baseURL}${mpc.dataReview}`,
			null,
			{
				params: {
					Markets,
					Division,
				},
			}
		);
		return response.status === 200;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const getDataReviewExcelMPC = async (Markets) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${mpc.getDataReviewExcel}`,
			{
				params: {
					Markets,
				},
				responseType: 'blob',
			}
		);
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
