import { useTranslation } from 'react-i18next';
import {
	createTheme,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	ThemeProvider,
	Typography,
} from '@mui/material';
import './ConfirmDailog.css';
import CustomButton from '../Button/CustomButton';

const theme = createTheme({
	components: {
		Dialog: {
			root: {
				fontSize: '1rem',
				background: 'whitesmoke',
			},
		},
	},
});
const ConfirmDailog = (props) => {
	const { t } = useTranslation();
	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={props.open}
				onClose={props.closeDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fontFamily="Roboto"
				PaperProps={{
					style: {
						backgroundColor: '#fff',
						alignItems: 'center',
						width: '23rem',
						height: 'auto',
						minHeight: '13rem',
						overflow: 'hidden',
						flexShrink: 0,
						position: 'relative',
						borderRadius: '0.875rem',
						border: '2px solid',
						borderColor: 'rgb(2 92 126)',
					},
				}}
			>
				<div className="dailogContainer">
					<DialogContent sx={{ overflow: 'hidden' }}>
						<DialogContentText
							id="alert-dialog-description"
							sx={{ padding: '0.2rem' }}
						>
							<Typography
								sx={{
									width: 'auto',
									height: '2rem',
									flexShrink: 0,
									color: '#000',
									fontFamily: 'Arial, Helvetica, sans-serif',
									fontSize: '1.375rem',
									fontStyle: 'normal',
									fontWeight: 600,
									lineHeight: '2.5rem',
								}}
							>
								{props.heading}
							</Typography>
							<br />
							<Typography
								sx={{
									color: '#000',
									fontFamily: 'Arial, Helvetica, sans-serif',
									fontSize: '1rem',
									fontStyle: 'normal',
									fontWeight: 400,
									lineHeight: '1.25rem',
									letterSpacing: '0.00219rem',
									paddingTop: '.56rem',
								}}
							>
								{props.content}
							</Typography>
						</DialogContentText>
					</DialogContent>
					<DialogActions
						sx={{
							float: 'right',
							overflow: 'hidden',
							marginBottom: '1rem',
						}}
					>
						<CustomButton
							text={t('yes')}
							width={'5rem'}
							handleClick={props?.confirm}
						/>
						&nbsp;&nbsp;
						<CustomButton
							text={t('no')}
							width={'5rem'}
							handleClick={props?.closeDialog}
						/>
					</DialogActions>
				</div>
			</Dialog>
		</ThemeProvider>
	);
};
export default ConfirmDailog;
