import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Rectangle,
	Cell,
	Legend,
} from 'recharts';

const CustomBarChart = (props) => {
	return (
		<BarChart width={750} height={400} data={props.data}>
			<CartesianGrid vertical={false} />
			<XAxis
				dataKey="key"
				label={{ value: props?.xLabel, position: 'outside' }}
			/>
			<YAxis
				label={{ value: props?.yLabel, angle: -90, position: 'insideLeft' }}
			/>
			<Tooltip />
			<Bar dataKey={'value'} barSize={70}>
				{props?.data.map((entry, index) => {
					let colorCode;
					if (entry.key === 'RETAILER') {
						colorCode = '#34778F';
					} else if (entry.key === 'TOC') {
						colorCode = '#194253';
					}

					return <Cell fill={colorCode} />;
				})}
			</Bar>
		</BarChart>
	);
};
export default CustomBarChart;
