import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
} from '@mui/material';
import { saveAs } from 'file-saver';
import Paper from '@mui/material/Paper';
import {
	downloadDocumentById,
	deleteDocumentById,
	uploadDocumentById,
} from '../../Services/resellerServices';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import Loader from '../../CommonComponents/Loader/Loader';
import { Trash2, Upload, Download } from 'react-feather';
import ConfirmDailog from '../Dailog/ConfirmDailog';
import SuccessToast from '../Toast/SuccessToast';

const DownloadTable = (props) => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [openToast, setOpenToast] = useState(false);
	const [openError, setOpenError] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [resultLoading, setResultLoading] = useState(false);
	const [showDailog, setShowDailog] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [country, setCountry] = useState(null);

	const fileInputRef = useRef(null);
	const handleIconClick = (id, countryCode) => {
		setSelectedRecord(id);
		setCountry(countryCode);
		fileInputRef.current.click();
	};

	const colheaders = [
		{
			key: 'isoCode',
			name: 'Country code',
		},
		{
			key: 'fileName',
			name: 'Document Name',
		},
	];
	const uploadFiles = async (e) => {
		try {
			e.preventDefault();
			const files = e.target.files;
			const formData = new FormData();
			formData.append('file', files[0]);
			formData.append('countryLanguage', country);
			formData.append('Id', selectedRecord);
			setResultLoading(true);
			const result = await uploadDocumentById(formData);
			if (result) {
				setOpenToast(true);
			}
			setResultLoading(false);
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	const closeDialog = () => {
		setShowDailog(false);
	};
	useEffect(() => {
		setData(props?.tableData);
	}, [props?.tableData]);
	const downloadFile = async (id, fileName) => {
		try {
			setResultLoading(true);
			const result = await downloadDocumentById(id);
			setResultLoading(false);
			if (result.status === 200) {
				saveAs(result.data, fileName);
			} else {
				let error = new Error();
				throw error;
			}
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const handleDelete = (id) => {
		console.log(id);
		setSelectedRecord(id);
		setShowDailog(true);
	};
	const deleteFile = async () => {
		try {
			setShowDailog(false);
			setResultLoading(true);
			const result = await deleteDocumentById(selectedRecord);
			setResultLoading(false);
			if (result) {
				setOpenToast(true);
				props?.refreshData();
			} else {
				let error = new Error();
				throw error;
			}
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	return (
		<Grid style={{ marginRight: '3em' }} className="downloadTable">
			<TableContainer component={Paper}>
				<Table>
					<TableHead sx={{ backgroundColor: '#dddddd54' }}>
						<TableRow>
							{colheaders.map((header) => (
								<TableCell
									align="center"
									sx={{
										fontWeight: 'bold',
										borderRight: '1px solid #ccc',
									}}
								>
									{header.name}
								</TableCell>
							))}
							<TableCell
								align="center"
								sx={{
									fontWeight: 'bold',
									borderRight: '1px solid #ccc',
								}}
							>
								{t('navlevel1_2')}
							</TableCell>
							<TableCell
								align="center"
								sx={{
									fontWeight: 'bold',
									borderRight: '1px solid #ccc',
								}}
							>
								Upload
							</TableCell>
							<TableCell
								align="center"
								sx={{
									fontWeight: 'bold',
									borderRight: '1px solid #ccc',
								}}
							>
								Delete
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{data?.map((data, index) => (
							<TableRow key={index}>
								{colheaders.map((header) => (
									<TableCell
										align="center"
										sx={{
											borderRight: '1px solid #ccc',
										}}
									>
										{data[header.key]}
									</TableCell>
								))}

								<TableCell
									align="center"
									sx={{
										borderRight: '1px solid #ccc',
									}}
								>
									<Download
										onClick={() => {
											downloadFile(data.id, data.fileName);
										}}
									/>
								</TableCell>
								<TableCell
									align="center"
									sx={{
										borderRight: '1px solid #ccc',
									}}
								>
									<IconButton
										onClick={() => handleIconClick(data.id, data.isoCode)}
										color="#000"
									>
										<Upload color="#000" />
									</IconButton>

									<input
										ref={fileInputRef}
										type="file"
										style={{ display: 'none' }}
										onChange={uploadFiles}
										multiple={false}
										accept="application/pdf"
										hidden
									/>
								</TableCell>
								<TableCell
									align="center"
									sx={{
										borderRight: '1px solid #ccc',
									}}
								>
									<Trash2 onClick={() => handleDelete(data.id)} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<br />
			<ConfirmDailog
				open={showDailog}
				closeDialog={closeDialog}
				heading={t('maintain_mpc_delete')}
				content={t('maintain_mpc_delete_message')}
				confirm={deleteFile}
			/>
			<ErrorToast
				open={openError}
				handleClose={handleCloseError}
				message={apiError}
			/>
			<Loader open={resultLoading} text={'Loading'} />
			<SuccessToast
				open={openToast}
				message={t('requestSuccess')}
				handleClose={(event, reason) => {
					if (reason === 'clickaway') {
						return;
					}
					setOpenToast(false);
				}}
			/>
		</Grid>
	);
};

export default DownloadTable;
