import React from 'react';
import { useEffect, useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	Typography,
	Card,
	Grid,
	Checkbox,
	FormControlLabel,
	Paper,
	Button,
	FormControl,
	OutlinedInput,
} from '@mui/material';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FilePreviewCard from '../../CommonComponents/Cards/FilePreviewCard';
import { useParams } from 'react-router-dom';
import '../EditReseller/EditReseller.css';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomTextField from '../../CommonComponents/TextField/CustomTextField';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import {
	getCountryListService,
	getAllCountryListService,
} from '../../Services/getCountryListService';
import {
	getResellerById,
	editReseller,
	deleteEvidenceById,
	addReseller,
	getEvidenceById,
} from '../../Services/resellerServices';
import SuccessToast from '../../CommonComponents/Toast/SuccessToast';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import Loader from '../../CommonComponents/Loader/Loader';

const VerificationForm = () => {
	const { id } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [fileValue, setFileValue] = useState([]);
	const [uploadFileError, setUploadFileError] = useState(null);
	const [previewUrl, setPreviewUrl] = useState('');
	const [dragOver, setDragOver] = useState(false);
	const [selectedAllCountry, setSelectedAllCountry] = useState('');
	const [identifiyingcountry, setidentifiyingcountry] = useState('');
	const [salesActivitycountryList, setSalesActivitycountryList] = useState([]);
	const [selectedSalesActivity, setSelectedSalesActivity] = useState([]);
	const [division, setDivision] = useState([]);
	const [date, setDate] = useState(null);
	const [openToast, setOpenToast] = useState(false);
	const [openError, setOpenError] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [resultLoading, setResultLoading] = useState(false);
	const [userData, setUserData] = useState(null);
	const [allCountries, setAllCountries] = useState([]);
	const [existingEvidences, setExistingEvidences] = useState([]);
	const [reload, setReload] = useState(false);
	const [action, setAction] = useState('');
	const [commentError, setCommentError] = useState(null);
	const [fieldErrors, setFieldErrors] = useState({});
	const [tc, setTc] = useState(false);

	const checkBoxlabel = { inputProps: { 'aria-label': 'Checkbox demo' } };
	useEffect(() => {
		getCountryListService()
			.then((countries) => {
				setSalesActivitycountryList(countries?.result);
			})
			.catch((err) => {
				console.error(err);
				setApiError(t('apiError'));
				setOpenError(true);
			});
		getAllCountryListService()
			.then((countries) => {
				setAllCountries(countries?.result);
			})
			.catch((err) => {
				console.error(err);
				setApiError(t('apiError'));
				setOpenError(true);
			});
	}, []);
	useEffect(() => {
		setResultLoading(true);
		getResellerById(id)
			.then((reseller) => {
				setUserData(reseller);
				if (
					reseller?.divisionalfocus?.includes('B') &&
					reseller?.divisionalfocus?.includes('T')
				) {
					setDivision([...division, 'B', 'T']);
				} else if (reseller?.divisionalfocus?.includes('T')) {
					setDivision([...division, 'T']);
				} else if (reseller?.divisionalfocus?.includes('B')) {
					setDivision([...division, 'B']);
				}
				setDate(reseller?.changetimestamp);
				setExistingEvidences(reseller?.evidences);
				setSelectedSalesActivity(reseller?.countries);
				setResultLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setApiError(t('apiError'));
				setOpenError(true);
				setResultLoading(false);
			});
	}, [id, reload]);
	useEffect(() => {
		if (userData?.country) {
			const country = allCountries.find(
				(country) => country.countryisocode === userData?.country
			);
			setSelectedAllCountry(country?.countryName);
			const idCountry = allCountries.find(
				(country) => country.countryisocode === userData?.identifiyingcountry
			);
			setidentifiyingcountry(idCountry?.countryName);
		}
	}, [userData, allCountries]);
	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	const handleDragEnter = (e) => {
		e.preventDefault();
		setDragOver(true);
	};
	const handleCancel = () => {
		navigate(-1);
	};
	const deleteEvidence = async (id) => {
		try {
			setResultLoading(true);
			const result = await deleteEvidenceById(id?.fileKey);
			if (result) {
				setReload(!reload);
				setResultLoading(false);
			} else {
				let error = new Error();
				throw error;
			}
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const downloadEvidence = async (id, fileName) => {
		try {
			setResultLoading(true);
			const result = await getEvidenceById(id);
			setResultLoading(false);
			if (result.status === 200) {
				saveAs(result.data, fileName);
			} else {
				throw new Error();
			}
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const validationSchema = Yup.object({
		name: Yup.string().required(t('Mandatory')),
		address: Yup.string().required(t('Mandatory')),
		housenumber: Yup.string().required(t('Mandatory')),
		zipcode: Yup.string().required(t('Mandatory')),
		city: Yup.string().required(t('Mandatory')),
		reportingRetailer: Yup.string().required(t('Mandatory')),
		reportingRetailerEmail: Yup.string().email().required(t('Mandatory')),
	});
	const handleDragLeave = () => {
		setDragOver(false);
	};
	const supportedMediaType = ['pdf'];
	const maxFiles = 5;

	const handleDrop = async (e) => {
		e.preventDefault();
		const files = e.dataTransfer.files;
		console.log(files);
		if (files?.length + fileValue?.length > maxFiles) {
			setUploadFileError('Maximum file count is 5');
		} else {
			setUploadFileError(null);
			setFileValue((prevState) => [...prevState, ...files]);
		}
	};
	const uploadFiles = async (e) => {
		e.preventDefault();
		const files = e.target.files;
		if (files?.length + fileValue?.length > maxFiles) {
			setUploadFileError(`${t('maxFileError')}`);
		} else {
			setUploadFileError(null);
			setFileValue((prevState) => [...prevState, ...files]);
		}
	};
	const uploadContainerStyle = {
		flexDirection: 'column',
		alignItems: 'center',
		border: '2px dashed #E5E5E5',
		width: '93%',
		margin: '0 auto',
		height: '18rem',
		display: 'flex',
		marginTop: '2rem',
	};
	//Onclick delete file uploaded
	const handleDelete = async (fileKey) => {
		const imgArr = fileValue;
		// removing selected file from the file preview cards
		imgArr.splice(fileKey.fileKey, 1);
		setFileValue(imgArr);
		//Updating the image url for the file preview cards
		const imageUrlArr = imgArr.map((file) => URL.createObjectURL(file));
		const objectUrlArray = imageUrlArr.reduce((acc, value) => {
			return [...acc, value];
		}, []);
		setPreviewUrl(objectUrlArray);
		setPreviewUrl(objectUrlArray);
	};
	const fileTypeValidation = async () => {
		try {
			let success = true;
			for await (const file of fileValue) {
				const parts = file?.name?.split('.');
				const extension = parts.pop();
				if (extension.toUpperCase() !== 'PDF') {
					success = false;
					break;
				}
			}
			return success;
		} catch (err) {
			return false;
		}
	};
	const submitForm = async (values) => {
		try {
			let requiredError = false;
			let fileValidationResult = false;
			if (fileValue.length !== 0) {
				fileValidationResult = await fileTypeValidation();
			}
			if (selectedAllCountry === null) {
				requiredError = true;
				setFieldErrors((prevErr) => ({
					...prevErr,
					allCountry: 'Country is required',
				}));
			}
			if (identifiyingcountry === null) {
				requiredError = true;
				setFieldErrors((prevErr) => ({
					...prevErr,
					identifiyingcountry: 'Identifying Country is required',
				}));
			}
			if (date === null) {
				requiredError = true;
				setFieldErrors((prevErr) => ({
					...prevErr,
					date: 'Created date is required',
				}));
			}
			if (division.length === 0) {
				requiredError = true;
				setFieldErrors((prevErr) => ({
					...prevErr,
					division: t('data_review_select_division_alert'),
				}));
			}
			if (tc === false) {
				requiredError = true;
				setFieldErrors((prevErr) => ({
					...prevErr,
					tc: t('Mandatory'),
				}));
			}
			values.country = selectedAllCountry;
			values.identifiyingcountry = identifiyingcountry;
			values.countries = selectedSalesActivity;
			values.changetimestamp = date;
			const divisionArray = division.sort((a, b) => b.localeCompare(a));
			values.divisionalfocus = divisionArray.length ? division.join('||') : '';
			delete values?.evidences;
			if (action === 'accept') {
				values.status = 1;
			} else if (action === 'decline') {
				values.status = 2;
				if (values?.comments === null || values?.comments.trim() === '') {
					setCommentError(t('commentError'));
					return;
				} else {
					setCommentError(null);
				}
			}
			if (!requiredError) {
				const formData = new FormData();

				for (let i = 0; i < fileValue.length; i++) {
					formData.append('file', fileValue[i]);
				}
				formData.append('data', JSON.stringify(values));
				setResultLoading(true);
				const result =
					action === 'accept' || action === 'decline'
						? await addReseller(formData)
						: await editReseller(formData);
				if (result) {
					setResultLoading(false);
					setReload(!reload);
					setOpenToast(true);
					setDivision([]);
					setFileValue([]);
				}
			} else {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	if (userData)
		return (
			<Formik
				onSubmit={submitForm}
				enableReinitialize
				initialValues={userData}
				validationSchema={validationSchema}
			>
				{(formik) => {
					const { handleChange, handleSubmit, setFieldError, errors } = formik;
					return (
						<form onSubmit={handleSubmit}>
							<div className="addNewResellerWrapper">
								<Typography
									noWrap
									sx={{
										color: '#151C2D',
										fontSize: '14px',
										fontStyle: 'normal',
										fontWeight: 600,
										fontFamily: 'Arial, Helvetica, sans-serif',
									}}
								>
									{t('navlevel1_6')}
								</Typography>
								<hr />
								<div className="addNewResellerContent">
									<Card
										sx={{
											padding: '2em 1.5em',
										}}
									>
										<Grid container width="100%" flexDirection={'column'}>
											<Typography
												sx={{
													fontWeight: 600,
													paddingBottom: '10px',
												}}
											>
												{t('reseller_label_company_panel')}
											</Typography>
											<Grid item className="companyDetails">
												<Grid item>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles required">
															{t('companyName')}
														</Typography>
														<CustomTextField
															placeholder={t('companyName')}
															width="90%"
															height="2.5em"
															name="name"
															// error={!!errors.name}
															handleChange={handleChange}
															defaultValue={userData?.name}
														/>
														<ErrorMessage
															name="name"
															component="div"
															className="error"
														/>
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles">
															{t('reseller_label_alternativename')}
														</Typography>
														<CustomTextField
															placeholder={t('reseller_label_alternativename')}
															width="90%"
															height="2.5em"
															name="alternativename"
															handleChange={handleChange}
															defaultValue={userData?.alternativename}
														/>
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles">
															{t('reseller_label_comregnumber')}
														</Typography>
														<CustomTextField
															placeholder={t('reseller_label_comregnumber')}
															width="90%"
															height="2.5em"
															name="commercialregistrationnumber"
															handleChange={handleChange}
															defaultValue={
																userData?.commercialregistrationnumber
															}
														/>
													</div>
												</Grid>
												<br />
												<div style={{ display: 'flex', flexDirection: 'row' }}>
													<Typography className="required">
														{t('reseller_label_publicCompany')}
													</Typography>
													<Checkbox
														{...checkBoxlabel}
														style={{ height: '2rem' }}
														onChange={(e) => {
															setTc(e.target.checked);
															if (e.target.checked) {
																setFieldErrors((prevError) => {
																	const updatedErrors = { ...prevError };
																	delete updatedErrors['tc'];
																	return updatedErrors;
																});
															} else {
																setFieldErrors((prevErr) => ({
																	...prevErr,
																	tc: t('Mandatory'),
																}));
															}
														}}
													/>
												</div>
												{'tc' in fieldErrors && (
													<div style={{ color: 'red' }} className="error">
														{fieldErrors['tc']}
													</div>
												)}
											</Grid>
											<br />
											<br />

											<Typography
												sx={{
													fontWeight: 600,
													paddingBottom: '10px',
												}}
											>
												{t('reseller_label_address_panel')}
											</Typography>
											<Grid item className="address">
												<Grid item>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles required">
															{t('reseller_label_address')}
														</Typography>
														<CustomTextField
															placeholder={t('reseller_label_address')}
															width="90%"
															height="2.5em"
															name="address"
															handleChange={handleChange}
															defaultValue={userData?.address}
														/>
														<ErrorMessage
															name="address"
															component="div"
															className="error"
														/>
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles required">
															{t('reseller_label_housenumber')}
														</Typography>
														<CustomTextField
															placeholder={t('reseller_label_housenumber')}
															width="90%"
															height="2.5em"
															name="housenumber"
															handleChange={handleChange}
															defaultValue={userData?.housenumber}
														/>
														<ErrorMessage
															name="housenumber"
															component="div"
															className="error"
														/>
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles required">
															{t('reseller_label_zipcode')}
														</Typography>
														<CustomTextField
															placeholder={t('reseller_label_zipcode')}
															width="90%"
															height="2.5em"
															name="zipcode"
															handleChange={handleChange}
															defaultValue={userData?.zipcode}
														/>
														<ErrorMessage
															name="zipcode"
															component="div"
															className="error"
														/>
													</div>
												</Grid>
												<br />
												<Grid item>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles required">
															{t('reseller_label_city')}
														</Typography>
														<CustomTextField
															placeholder={t('reseller_label_city')}
															width="90%"
															height="2.5em"
															name="city"
															handleChange={handleChange}
															defaultValue={userData?.city}
														/>
														<ErrorMessage
															name="city"
															component="div"
															className="error"
														/>
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles required">
															{t('reseller_label_country')}
														</Typography>
														<AutoCompleteCountryDropdown
															width="90%"
															height="2.5em"
															placeholder={t('reseller_label_country')}
															value={selectedAllCountry}
															onChange={(newValue) => {
																if (newValue === null) {
																	setFieldErrors((prevErr) => ({
																		...prevErr,
																		allCountry: 'Country is required',
																	}));
																} else {
																	setFieldErrors((prevError) => {
																		const updatedErrors = { ...prevError };
																		delete updatedErrors['allCountry'];
																		return updatedErrors;
																	});
																}
																setSelectedAllCountry(newValue);
															}}
															country="All"
														/>
														{'allCountry' in fieldErrors && (
															<div style={{ color: 'red' }} className="error">
																{fieldErrors['allCountry']}
															</div>
														)}
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles">
															{t('reseller_label_phonenumber')}
														</Typography>
														<CustomTextField
															placeholder={t('reseller_label_phonenumber')}
															width="90%"
															height="2.5em"
															name="telephonenumber"
															handleChange={handleChange}
															defaultValue={userData?.telephonenumber}
														/>
													</div>
												</Grid>
												<br />
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles">
														{t('reseller_label_faxnumber')}
													</Typography>
													<CustomTextField
														placeholder={t('reseller_label_faxnumber')}
														width="90%"
														height="2.5em"
														name="faxnumber"
														handleChange={handleChange}
														defaultValue={userData?.faxnumber}
													/>
												</div>
											</Grid>
											<br />
											<br />
											<Typography
												sx={{
													fontWeight: 600,
													paddingBottom: '10px',
												}}
											>
												{t('reseller_label_furtherinformation_panel')}
											</Typography>
											<Grid item className="futherInformation">
												<Grid item>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles">
															{t('reseller_label_homepage')}
														</Typography>
														<CustomTextField
															placeholder={t('reseller_label_homepage')}
															width="90%"
															height="2.5em"
															handleChange={handleChange}
															name="homepage"
															defaultValue={userData?.homepage}
														/>
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles required">
															{t('reseller_label_identifyingcountry')}
														</Typography>
														<AutoCompleteCountryDropdown
															width="90%"
															height="2.5em"
															placeholder={t(
																'reseller_label_identifyingcountry'
															)}
															value={identifiyingcountry}
															onChange={(newValue) => {
																if (newValue === null) {
																	setFieldErrors((prevErr) => ({
																		...prevErr,
																		identifiyingcountry:
																			'Identifying Country is required',
																	}));
																} else {
																	setFieldErrors((prevError) => {
																		const updatedErrors = { ...prevError };
																		delete updatedErrors['identifiyingcountry'];
																		return updatedErrors;
																	});
																}
																setidentifiyingcountry(newValue);
															}}
															country="European"
															defaultValue={userData?.country}
														/>
														{'identifiyingcountry' in fieldErrors && (
															<div style={{ color: 'red' }} className="error">
																{fieldErrors['identifiyingcountry']}
															</div>
														)}
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '33%',
														}}
													>
														<Typography className="fieldTitles required">
															{t('reseller_label_dateofverificationbympc')}
														</Typography>
														<LocalizationProvider dateAdapter={AdapterDayjs}>
															<DemoContainer components={['DatePicker']}>
																<DatePicker
																	slotProps={{
																		textField: {
																			size: 'small',
																			placeholder: t(
																				'reseller_label_dateofverificationbympc'
																			),
																		},
																	}}
																	onChange={(value) => {
																		console.log(value);
																		if (value === null) {
																			setFieldErrors((prevErr) => ({
																				...prevErr,
																				date: 'Created date is required',
																			}));
																			setDate(value);
																		} else {
																			setFieldErrors((prevError) => {
																				const updatedErrors = { ...prevError };
																				delete updatedErrors['date'];
																				return updatedErrors;
																			});
																			setDate(value.format('YYYY-MM-DD'));
																		}
																	}}
																	defaultValue={
																		userData?.changetimestamp
																			? dayjs(userData.changetimestamp)
																			: null
																	}
																/>
															</DemoContainer>
														</LocalizationProvider>
														{'date' in fieldErrors && (
															<div style={{ color: 'red' }} className="error">
																{fieldErrors['date']}
															</div>
														)}
													</div>
												</Grid>
												<br />
											</Grid>
											<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography className="required">
													{t('reseller_label_divisionalfocus')}
												</Typography>
												&nbsp;&nbsp;
												<FormControlLabel
													width="25%"
													control={<Checkbox name="bus" />}
													label={t('maintain_mpc_division_bus')}
													checked={division.includes('B')}
													onChange={(e) => {
														if (e.target.checked) {
															setFieldErrors((prevError) => {
																const updatedErrors = { ...prevError };
																delete updatedErrors['division'];
																return updatedErrors;
															});
															setDivision([...division, 'B']);
														} else {
															const filteredDivision = division.filter(
																(div) => div !== 'B'
															);
															if (filteredDivision.length === 0) {
																setFieldErrors((prevErr) => ({
																	...prevErr,
																	division: t(
																		'data_review_select_division_alert'
																	),
																}));
															}
															setDivision(filteredDivision);
														}
													}}
												/>
												<FormControlLabel
													width="25%"
													control={<Checkbox name="truck" />}
													label={t('maintain_mpc_division_truck')}
													checked={division.includes('T')}
													onChange={(e) => {
														if (e.target.checked) {
															setFieldErrors((prevError) => {
																const updatedErrors = { ...prevError };
																delete updatedErrors['division'];
																return updatedErrors;
															});
															setDivision([...division, 'T']);
														} else {
															const filteredDivision = division.filter(
																(div) => div !== 'T'
															);
															if (filteredDivision.length === 0) {
																setFieldErrors((prevErr) => ({
																	...prevErr,
																	division: t(
																		'data_review_select_division_alert'
																	),
																}));
															}
															setDivision(filteredDivision);
														}
													}}
												/>
											</Grid>
											{'division' in fieldErrors && (
												<div style={{ color: 'red' }} className="error">
													{t('data_review_select_division_alert')}
												</div>
											)}
											<br />
											<br />
											<Typography
												sx={{
													fontWeight: 600,
													paddingBottom: '10px',
												}}
											>
												{t('reseller_label_knownsalesactivityEU_panel')}
											</Typography>
											<Grid item className="knowActivities" width="100%">
												<Grid item className="countryCheckBoxWrapper">
													{salesActivitycountryList?.map((country) => {
														return (
															<Grid item className="countries" width="25%">
																<FormControlLabel
																	control={
																		<Checkbox name={country.countryisocode} />
																	}
																	label={
																		<Typography style={{ fontSize: '14px' }}>
																			{country.countryName}
																		</Typography>
																	}
																	onChange={(e) => {
																		if (e.target.checked) {
																			setSelectedSalesActivity([
																				...selectedSalesActivity,
																				country,
																			]);
																		} else {
																			const filteredSalesActivity =
																				selectedSalesActivity.filter(
																					(data) =>
																						data?.countryisocode !==
																						country.countryisocode
																				);
																			setSelectedSalesActivity(
																				filteredSalesActivity
																			);
																		}
																	}}
																	checked={selectedSalesActivity.some(
																		(c) =>
																			c.countryisocode ===
																			country.countryisocode
																	)}
																/>
															</Grid>
														);
													})}
												</Grid>
											</Grid>
											<br />
											<br />
											<Typography
												sx={{
													fontWeight: 600,
													paddingBottom: '10px',
												}}
											>
												{t('reseller_label_remarks')}
											</Typography>
											<Typography className="fieldTitles">
												{t('reseller_label_remarksnotes')}
											</Typography>
											<Grid item className="dataInput" width="100%">
												<FormControl sx={{ width: '100%' }}>
													<OutlinedInput
														placeholder="Don't add personal and confidential data"
														multiline
														rows={5}
														name="remarks"
														onChange={handleChange}
														defaultValue={userData?.remarks}
													/>
												</FormControl>
											</Grid>
											<br />
											<br />
											<Typography
												sx={{
													fontWeight: 600,
													paddingBottom: '10px',
												}}
											>
												{t('reseller_label_evidences_panel')}
											</Typography>
											{existingEvidences.length > 0 && (
												<Grid container direction={'row'} columnGap={2}>
													{existingEvidences?.map((evidence) => {
														return (
															<Grid
																item
																key={evidence.evidenceid}
																style={{ width: '49%', marginBottom: '1rem' }}
															>
																<FilePreviewCard
																	fileKey={evidence.evidenceid}
																	fileName={evidence.filename}
																	fileSize={
																		evidence.fileSize
																			? evidence.fileSize * 1024 * 1024
																			: null
																	}
																	onDeleteFile={deleteEvidence}
																	width="auto"
																	showDownload={true}
																	onDownload={() => {
																		downloadEvidence(
																			evidence.evidenceid,
																			evidence.filename
																		);
																	}}
																/>
															</Grid>
														);
													})}
												</Grid>
											)}
											<Grid
												item
												width="100%"
												sx={{
													display: 'flex',
													justifyContent: 'end',
													paddingRight: '2rem',
												}}
											></Grid>
											<Grid item className="documents" width="100%">
												<Paper
													className="fileUpload"
													style={{
														...uploadContainerStyle,
														...(dragOver ? { border: '2px dashed blue' } : {}),
														paddingTop: '3rem',
														paddingBottom: '1em',
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
													}}
													onDragEnter={handleDragEnter}
													onDragOver={handleDragEnter}
													onDragLeave={handleDragLeave}
													onDrop={handleDrop}
													onChange={uploadFiles}
												>
													<label htmlFor="fileInput">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="100"
															height="70"
															viewBox="0 0 146 122"
															fill="none"
														>
															<path
																d="M73 62.0176V119"
																stroke="#C8C8C8"
																strokeWidth="6"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M125.798 100.684C131.932 97.3087 136.778 91.9673 139.571 85.5031C142.364 79.039 142.945 71.8201 141.221 64.9859C139.498 58.1518 135.569 52.0915 130.054 47.7616C124.539 43.4316 117.752 41.0787 110.765 41.074H102.84C100.937 33.6417 97.3882 26.7417 92.4621 20.8928C87.5359 15.0439 81.3601 10.3982 74.399 7.30508C67.4379 4.21193 59.8727 2.75179 52.272 3.03444C44.6713 3.31709 37.233 5.33516 30.5163 8.93695C23.7996 12.5387 17.9793 17.6305 13.493 23.8295C9.00659 30.0284 5.97092 37.1732 4.61417 44.7268C3.25742 52.2803 3.61489 60.046 5.65972 67.44C7.70455 74.834 11.3835 81.664 16.42 87.4163"
																stroke="#C8C8C8"
																strokeWidth="6"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M99.4562 86.4386L73.0001 62.0176L46.5439 86.4386"
																stroke="#C8C8C8"
																strokeWidth="6"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>

														<Typography
															sx={{
																width: 'auto',
																height: '2rem',
																textAlign: 'center',
																fontSize: '0.875rem',
																fontStyle: 'normal',
																fontWeight: 400,
																lineHeight: '2.5rem',
																color: '#000',
															}}
														>
															{t('dragAndDropText')}
														</Typography>

														<Typography
															sx={{
																width: '11.25rem',
																height: '2rem',
																textAlign: 'center',
																fontSize: '0.875rem',
																fontStyle: 'normal',
																fontWeight: 400,
																lineHeight: '2.5rem',
																color: '#000',
															}}
														>
															{t('or')}
														</Typography>
													</label>
													<br />
													<Button
														component="label"
														sx={{
															background: '#E6E6E6',
															color: '#000',
															display: 'flex',
															width: 'auto',
															height: '2.5rem',
															justifyContent: 'center',
															alignItems: 'center',
															flexShrink: 0,
															borderRadius: '.175rem',
															border: '1px solid',
															textTransform: 'none',
															'&:hover ': {
																transition: 'background 0.3s',
																backgroundColor: '#fff !important',
																color: '#194253',

																border: '1px solid',
															},
														}}
													>
														{t('selectFiles')}
														<input
															type="file"
															accept="application/pdf"
															hidden
															id="fileInput"
															multiple
														/>
													</Button>
													<div
														style={{
															display: 'flex',
															justifyContent: 'center',
														}}
													>
														<p style={{ color: '#bcbcbc' }}>
															{`${t('maxFileError')}`}
														</p>
													</div>
												</Paper>
												<div
													style={{ display: 'flex', justifyContent: 'center' }}
												>
													<p className="red-text">{uploadFileError} </p>
												</div>

												<Grid
													container
													width="93%"
													style={{
														margin: '1rem auto 0',
														justifyContent: 'space-between',
													}}
												>
													{Object.keys(fileValue)?.map((file, index) => (
														<Grid
															item
															key={fileValue[file].name}
															style={{ width: '49%', marginBottom: '1rem' }}
														>
															<FilePreviewCard
																fileKey={file}
																fileName={fileValue[file].name}
																fileSize={fileValue[file].size}
																onDeleteFile={handleDelete}
																previewimage={previewUrl[index]}
																width="auto"
															/>
														</Grid>
													))}
												</Grid>
											</Grid>
											<br />
											<Typography
												sx={{
													fontWeight: 600,
													paddingBottom: '10px',
												}}
											>
												{t('verify_reseller_header_retailer_contact')}
											</Typography>
											<Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('report_reseller_label_retailer_name')}
													</Typography>
													<CustomTextField
														placeholder={t(
															'report_reseller_label_retailer_name'
														)}
														width="90%"
														height="2.5em"
														name="reportingRetailer"
														handleChange={handleChange}
														defaultValue={userData?.reportingRetailer}
													/>
													<ErrorMessage
														name="reportingRetailer"
														component="div"
														className="error"
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('report_reseller_label_retailer_email')}
													</Typography>
													<CustomTextField
														placeholder={t(
															'report_reseller_label_retailer_email'
														)}
														width="90%"
														height="2.5em"
														name="reportingRetailerEmail"
														handleChange={handleChange}
														defaultValue={userData?.reportingRetailerEmail}
													/>
													<ErrorMessage
														name="reportingRetailerEmail"
														component="div"
														className="error"
													/>
												</div>
											</Grid>
											<br />
											<Typography className="fieldTitles">
												{t('report_reseller_comment')}
											</Typography>
											<Grid item className="dataInput" width="100%">
												<FormControl sx={{ width: '100%' }}>
													<OutlinedInput
														multiline
														rows={3}
														name="comments"
														onChange={handleChange}
														defaultValue={userData?.comments}
													/>
												</FormControl>
												{commentError && (
													<p
														className="red-text"
														style={{ marginBottom: '5em', fontSize: '16px' }}
													>
														{commentError}
													</p>
												)}
											</Grid>
											<Grid
												item
												className="btn"
												width="100%"
												display={'flex'}
												justifyContent={'flex-end'}
												marginTop="2em"
											>
												<CustomButton
													text={t('verify_reseller_button_cancel')}
													width="7rem"
													handleClick={handleCancel}
												/>
												<CustomButton
													text={t('verify_reseller_button_save')}
													handleClick={() => {
														setAction('save');
														handleSubmit();
													}}
													width="7rem"
													marginLeft="2em"
												/>
												<CustomButton
													text={t('verify_reseller_button_accept')}
													handleClick={() => {
														setAction('accept');
														handleSubmit();
													}}
													width="7rem"
													marginLeft="2em"
												/>
												<CustomButton
													text={t('verify_reseller_button_decline')}
													handleClick={() => {
														setAction('decline');
														handleSubmit();
													}}
													width="7rem"
													marginLeft="2em"
												/>

												<br />
												<br />
											</Grid>
										</Grid>
									</Card>
								</div>
								<br />
								<p
									className="red-text"
									style={{ marginBottom: '5em', fontSize: '14px' }}
								>
									{t('add_reseller_warning')}
								</p>
							</div>
							<ErrorToast
								open={openError}
								handleClose={handleCloseError}
								message={apiError}
							/>
							<SuccessToast
								open={openToast}
								message={t('requestSuccess')}
								handleClose={(event, reason) => {
									if (reason === 'clickaway') {
										return;
									}
									setOpenToast(false);
									navigate(-1);
								}}
							/>
							<Loader open={resultLoading} text={'Loading'} />
						</form>
					);
				}}
			</Formik>
		);
	else return <Loader open={resultLoading} text={'Loading'} />;
};

export default VerificationForm;
