import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './Home.css';
import { useTranslation, Trans } from 'react-i18next';
import DataReviewDailog from '../../CommonComponents/Dailog/DataReviewDailog';
import { roles } from '../../Constants/Permission';

const Home = () => {
	const { t } = useTranslation();
	const user_info = useSelector((state) => state?.auth?.user);
	const currentRole = useSelector((state) => state?.role?.currentRole);
	const [showReminder, setShowReminder] = useState(false);
	useEffect(() => {
		if (currentRole === roles.HQ || currentRole === roles.MPC) {
			if (user_info?.dataReview === 'no') {
				const data_review_reminder = localStorage.getItem(
					'data_review_reminder'
				);
				if (data_review_reminder === 'false') {
					setShowReminder(false);
				} else {
					setShowReminder(true);
				}
			} else {
				setShowReminder(false);
			}
		}
	}, [user_info]);
	return (
		<div className="homeContainer">
			<br />
			<div className="homeContent">
				<p>{t('contextwelcome1')}</p>
				<p>
					<Trans i18nKey="contextwelcome2" />
				</p>
				<p>{t('contextwelcome3')}</p>
				<p>
					{/* {`${t('contextwelcome3_1')} `}
					{`${t('contextwelcome3_2')} ${t('contextwelcome3_3')}`} */}
					{t('contextwelcome3_1')}
					<b>{t('contextwelcome3_2')}</b>
					{t('contextwelcome3_3')}
				</p>

				<p>{t('contextwelcome4')}</p>
				<p>{t('contextwelcome5')}</p>

				<p className="red-text alert-text">{t('add_reseller_warning')}</p>
			</div>
			{showReminder === true && (
				<DataReviewDailog
					closeDailog={() => {
						setShowReminder(false);
						localStorage.setItem('data_review_reminder', false);
					}}
				/>
			)}
		</div>
	);
};

export default Home;
