import axios from 'axios';
import { baseURL, reseller, common } from '../Constants/APIUrls';

export const searchResellerService = async (searchTerm, pageNum, pageSize) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.searchReseller.replace(':Name', searchTerm)}`,
			{
				params: {
					pageNo: pageNum,
					pageSize: pageSize,
				},
			}
		);
		return response;
		// const results = response ? response.data : [];
		// return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const getAllResellerService = async (market, pageNo, pageSize, Name) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.editResellerList}`,
			{
				params: {
					Market: market,
					pageNo,
					pageSize,
					Name,
				},
			}
		);

		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const geResellerByCountryService = async (selectedCountry) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${reseller.resellerByCountryist.replace(':selectedCountry', selectedCountry)}`
		);

		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const getResellersListCsv = async (selectedCountry) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${reseller.csv.replace(':Country', selectedCountry)}`
		);

		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const getResellersListExcel = async (selectedCountry) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${reseller.xsl.replace(':Country', selectedCountry)}`
		);

		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const addReseller = async (formData) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.post(
			`${baseURL}${common.addReseller}`,
			formData
		);
		return response.status === 200;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};

export const getResellerById = async (id) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.getResellerById}`,
			{
				params: {
					Id: id,
				},
			}
		);
		return response.data?.result;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};

export const editReseller = async (formData) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.post(
			`${baseURL}${common.editReseller}`,
			formData
		);
		return response.status === 200;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};
export const getResellerHistory = async (Id) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.getResellerHistory}`,
			{
				params: {
					Id,
				},
				responseType: 'blob',
			}
		);
		return response;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};
export const deleteEvidenceById = async (evidenceId) => {
	try {
		console.log('Evidence id', evidenceId);
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.delete(
			`${baseURL}${common.deleteEvidence}`,
			{
				params: {
					evidenceId,
				},
			}
		);
		return response.status === 200;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};
export const exportCSVData = async (selectedCountry) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(`${baseURL}${common.csv}`, {
			params: {
				Market: selectedCountry,
			},
			responseType: 'blob',
		});
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const exportXLSData = async (selectedCountry) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(`${baseURL}${common.xsl}`, {
			params: {
				Market: selectedCountry,
			},
			responseType: 'blob',
		});
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const getVerifyResellerList = async (Market, pageNo, pageSize) => {
	try {
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.verifyResellerList}`,
			{
				params: {
					Market,
					pageNo,
					pageSize,
				},
			}
		);

		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const getVerifyResellerExcel = async (Market) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.verifyResellerExcel}`,
			{
				params: {
					Market,
				},
				responseType: 'blob',
			}
		);
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const getVerifyResellerCSV = async (Market) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.verifyResellerCSV}`,
			{
				params: {
					Market,
				},
				responseType: 'blob',
			}
		);
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const getDocumentsByFlag = async (Flag, pageNo, pageSize) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.getDocumentsByFlag}`,
			{
				params: {
					Flag,
					pageNo,
					pageSize,
				},
			}
		);
		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const downloadDocumentById = async (Id) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.downloadDocumentById}`,
			{
				params: {
					Id,
				},
				responseType: 'blob',
			}
		);
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const deleteDocumentById = async (Id) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.delete(
			`${baseURL}${common.deleteDocumentById}`,
			{
				params: {
					Id,
				},
			}
		);
		return response.status === 200;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const uploadDocumentById = async (formData) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.post(
			`${baseURL}${common.uploadDocumentById}`,
			formData
		);
		return response.status === 200;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const deleteResellerById = async (Id) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.delete(
			`${baseURL}${common.deleteResellerById}`,
			{
				params: {
					Id,
				},
			}
		);
		return response.status === 200;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const getEvidenceById = async (evidenceId) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.getEvidenceById}`,
			{
				params: {
					evidenceId,
				},
				responseType: 'blob',
			}
		);
		return response;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
